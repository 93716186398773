import { useState, useEffect, useCallback } from "react";
import { tagWithDataSourceType } from "../api/api";
import { WebcamDto } from "../api/ifis";
import { useApi } from "../contexts/ApiContext";
import { DataSourceType } from "./useDataSources";

export default function useWebcams(enabled: boolean) {
    const [webcams, setWebcams] = useState<WebcamDto[]>([]);
    const { getJson } = useApi();

    const updateWebcams = useCallback(async () => {
        const response = await getJson(`/roadcam`);
        if (!response) return;
        setWebcams(tagWithDataSourceType(response, DataSourceType.RoadCamera));
    }, [getJson, setWebcams]);

    useEffect(() => {
        if (!enabled) {
            setWebcams([]);
            return;
        }

        updateWebcams();

        const pollInterval = 60000; //1min
        const timerId = setInterval(() => {
            updateWebcams();
        }, pollInterval);

        return () => clearInterval(timerId);
    }, [enabled, updateWebcams]);

    return webcams;
}
