import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Map } from "mapbox-gl";
import { useSettings } from "../../contexts/SettingsContext";

type CrosshairProps = {
    map: Map | null;
};

const useStyles = makeStyles((theme) => ({
    verticalBar: {
        zIndex: 1,
        height: "100%",
        width: "2px",
        position: "absolute",
        left: "50%",
        backgroundColor: "rgba(100,100,100,0.5)",
        boxShadow: "0 0 5px rgb(100,100,100)",
        pointerEvents: "none",
    },
    horzontalBar: {
        zIndex: 1,
        height: "2px",
        width: "100%",
        position: "absolute",
        top: "50%",
        backgroundColor: "rgba(100,100,100,0.5)",
        boxShadow: "0 0 5px rgb(100,100,100)",
        pointerEvents: "none",
    },
}));

export default function Crosshair({ map }: CrosshairProps) {
    const classes = useStyles();
    const { distanceUnit } = useSettings();

    if (map == null || map.getPitch() > 10) {
        return null;
    }

    const center: [number, number] = [
        map.getContainer().clientHeight / 2,
        map.getContainer().clientWidth / 2,
    ];
    const centerCoords = map.unproject(center);

    const top: [number, number] = [map.getContainer().clientHeight, center[1]];
    const topCoords = map.unproject(top);
    const maxDistanceTop =
        centerCoords.distanceTo(topCoords) * toMeters[distanceUnit];
    const ratioTop = (center[0] - top[0]) / maxDistanceTop;

    const left: [number, number] = [center[0], map.getContainer().clientWidth];
    const leftCoords = map.unproject(left);
    const maxDistanceLeft =
        centerCoords.distanceTo(leftCoords) * toMeters[distanceUnit];
    const ratioLeft = (center[1] - left[1]) / maxDistanceLeft;

    const maxDistance = Math.max(maxDistanceLeft, maxDistanceTop);
    const numberOfMarkers =
        maxDistance / Math.min(maxDistanceLeft, maxDistanceTop) > 1.5 ? 3 : 2;

    const scale = getScale(maxDistance, numberOfMarkers, distanceUnit);

    return (
        <div>
            {getDistanceMarkersY(scale, ratioTop, center)}
            {getDistanceMarkersX(scale, ratioLeft, center)}
            <div className={classes.verticalBar}></div>
            <div className={classes.horzontalBar}></div>
        </div>
    );
}

const toMeters = {
    m: 1,
    nmi: 0.000539956803,
};

const getDistanceMarkersY = (
    scale: scale,
    ratio: number,
    center: [number, number]
) => {
    return scale.distances.map((x, i) => (
        <Typography
            key={`dist-point-y-${i}`}
            style={{
                position: "absolute",
                top: center[0] + ratio * x,
                right: center[1],
                transform: "translate(88%, -50%)",
                pointerEvents: "none",
                textAlign: "center",
            }}
        >
            {scale.label(x)}
        </Typography>
    ));
};

const getDistanceMarkersX = (
    scale: scale,
    ratio: number,
    center: [number, number]
) => {
    return scale.distances.map((x, i) => (
        <Typography
            key={`dist-point-x-${i}`}
            style={{
                position: "absolute",
                top: center[0],
                right: center[1] + ratio * -x,
                transform: "translate(50%, 35%) rotate(90deg) ",
                pointerEvents: "none",
                textAlign: "center",
                width: "100%",
            }}
        >
            {scale.label(x)}
        </Typography>
    ));
};

const getLabelKm = (distance: number) => {
    const unit = distance < 1000 ? "m" : "km";
    const prettyDistance = distance < 1000 ? distance : distance / 1000;

    return `\u2014 ${prettyDistance}${unit}`;
};

const getLabelNmi = (distance: number) => {
    return `\u2014 ${distance}nmi`;
};

const scales = {
    m: {
        distances: [
            100, 500, 1000, 2000, 5000, 10000, 25000, 50000, 100000, 200000,
        ],
        label: getLabelKm,
    },
    nmi: {
        distances: [0.1, 0.5, 1, 3, 5, 10, 20, 50, 100, 150],
        label: getLabelNmi,
    },
};

type scale = ReturnType<typeof getScale>;

export type distanceUnits = keyof typeof scales;

const getScale = (
    maxDistance: number,
    numberOfMarkers: number,
    unit: distanceUnits
) => {
    const scale = scales[unit];

    return {
        ...scale,
        distances: scale.distances
            .filter((x) => x <= maxDistance)
            .slice(-numberOfMarkers),
    };
};
