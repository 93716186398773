import { useState } from "react";
import { MediumOrHeavy, DayOrNight } from "../utils/constants";
import useLocalStorage from "./useLocalStorage";

export type OperationHookType = {
    aircraftClass: MediumOrHeavy;
    operationTime: DayOrNight;
    isHeavy: () => boolean;
    isNight: () => boolean;
    toggleAircraftClass: () => void;
    toggleOperationTime: () => void;
};

export default function useOperation(): OperationHookType {
    const [saveAircraftClass, getAircraftClass] = useLocalStorage(
        "aircraft-class"
    );

    const [saveOperationTime, getOperationTime] = useLocalStorage(
        "operation-time"
    );

    const [aircraftClass, setAircraftClass] = useState(
        (getAircraftClass() as MediumOrHeavy) || MediumOrHeavy.medium
    );

    const [operationTime, setOperationTime] = useState(
        (getOperationTime() as DayOrNight) || DayOrNight.day
    );

    const isHeavy = () => {
        return aircraftClass === MediumOrHeavy.heavy;
    };

    const isNight = () => {
        return operationTime === DayOrNight.night;
    };

    const toggleAircraftClass = () => {
        const newAircraftClass = isHeavy()
            ? MediumOrHeavy.medium
            : MediumOrHeavy.heavy;
        setAircraftClass(newAircraftClass);
        saveAircraftClass(newAircraftClass);
    };

    const toggleOperationTime = () => {
        const newOperationTime = isNight() ? DayOrNight.day : DayOrNight.night;
        setOperationTime(newOperationTime);
        saveOperationTime(newOperationTime);
    };

    return {
        aircraftClass,
        operationTime,
        isHeavy,
        isNight,
        toggleAircraftClass,
        toggleOperationTime,
    };
}
