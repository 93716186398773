import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { maxWidth: 600, margin: 8 },
        header: { marginTop: 8 },
        link: { color: theme.palette.primary.light },
    })
);

const About = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h3">
                AeroMet IFIS v{process.env.REACT_APP_VERSION}
            </Typography>
            <Typography variant="h4">In-Flight Information Services</Typography>
            <Typography variant="body1">© 2021 MMAviation</Typography>
            <Typography variant="h5" className={classes.header}>
                Disclaimer
            </Typography>
            <Typography variant="body1">
                IFIS aggregates and presents data made available by public data
                sources and other third parties. It is offered as an aid in
                flight planning and operations but is by no means a replacement
                for on board systems and equipment. MMAviation gives no warranty
                and accepts no responsibility or liability for the accuracy or
                the completeness of the information contained in this
                application.
            </Typography>
            <Typography variant="h5" className={classes.header}>
                Data providers
            </Typography>
            <ul>
                <li>MET Norway</li>
                <li>Kartverket</li>
                <li>Statens vegvesen</li>
                <li>Netatmo</li>
                <li>Avinor</li>
                <li>Noregs vassdrags- og energidirektorat (NVE)</li>
            </ul>
            <Typography variant="h5" className={classes.header}>
                Contact
            </Typography>
            <a href="mailto:office@mmaviation.com" className={classes.link}>
                office@mmaviation.com
            </a>
        </div>
    );
};

export default About;
