import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import msalInstance, { loginRequest } from "./utils/authProvider";
import defaultTheme from "./utils/themeProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import { SettingsProvider } from "./contexts/SettingsContext";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ApiProvider } from "./contexts/ApiContext";
import reactPlugin from "./utils/appInsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Splash from "./Splash";

ReactDOM.render(
    <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                    errorComponent={(e: any) => (
                        <Splash message={e.error} theme={defaultTheme} />
                    )}
                    loadingComponent={() => (
                        <Splash
                            message="Authentication in progress..."
                            theme={defaultTheme}
                        />
                    )}
                >
                    <ApiProvider>
                        <ThemeProvider theme={defaultTheme}>
                            <SettingsProvider>
                                <App />
                            </SettingsProvider>
                        </ThemeProvider>
                    </ApiProvider>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </AppInsightsContext.Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

const configuration = {
    onUpdate: (registration: any) => {
        if (registration && registration.waiting) {
            if (
                window.confirm(
                    "New version available!  refresh to update your app?"
                )
            ) {
                registration.waiting.postMessage({ type: "SKIP_WAITING" });
                window.location.reload();
            }
        }
    },
};

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
