import { Button, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useSettings } from "../../contexts/SettingsContext";

const ZoomPresets = () => {
    const { zoom } = useSettings();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            flexDirection: "column",
        },
        button: {
            border: "2px solid",
            color: "black",
            borderColor: "black",
            marginTop: -2,
            // marginRight: matches ? -2 : 0,
            borderRadius: 0,
            height: matches ? 40 : 32,
            width: 40,
            minWidth: 40,
            padding: 0,
            fontWeight: 600,
        },
    }));
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {zoom.zoomPresets.map((x: number, i: number) => (
                <Button
                    key={`preset-button-${i}`}
                    onClick={() => zoom.setZoomLevel(x)}
                    className={classes.button}
                    variant="contained"
                    size="medium"
                    color={zoom.currZoomPreset === i ? "primary" : "default"}
                >
                    Z{i + 1}
                </Button>
            ))}
        </div>
    );
};

export default ZoomPresets;
