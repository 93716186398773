import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ReactComponent as VisibilityIcon } from "../../icons/iconmonstr-eye-4.svg";
import { CardHeader, Grid } from "@material-ui/core";
import LabelValue from "../core/LabelValue";
import { VisibilityReport } from "../../api/ifis";

export default function VisibilityCard({
    visibility,
}: {
    visibility: VisibilityReport | null | undefined;
}) {
    if (visibility == null || visibility.observations.length === 0) {
        return null;
    }

    const observation = visibility.observations[0];

    return (
        <Card variant="outlined">
            <CardHeader
                title="Visibility"
                avatar={<VisibilityIcon fill="white" />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label={`Visibility (${observation.periodInMinutes} min)`}
                            value={observation.averageVisibility}
                            slashesWhenEmpty
                            unitKey={"visibility"}
                        ></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
