import { debounce } from "@material-ui/core";
import { useState, useEffect, useCallback, useMemo } from "react";
import { tagWithDataSourceType } from "../api/api";
import { MarineVesselDto } from "../api/ifis";
import { useApi } from "../contexts/ApiContext";
import { useSettings } from "../contexts/SettingsContext";
import { DataSourceType } from "./useDataSources";
import { GeoArea } from "./useViewArea";

type MarineTrafficQuery = {
    lat: number;
    lon: number;
    distanceInKm: number;
    minLength: number;
};

export default function useMarineTraffic(
    enabled: boolean,
    shouldLoad: boolean,
    requestArea: GeoArea
) {
    const { zoom } = useSettings();
    const [marineVessels, setMarineVessels] = useState<MarineVesselDto[]>([]);
    const { getJson } = useApi();

    const updateMarineTraffic = useCallback(
        async ({ distanceInKm, lat, lon, minLength }: MarineTrafficQuery) => {
            const vessels = await getJson(
                `/traffic/marine?km=${distanceInKm}&lat=${lat}&lon=${lon}&minLength=${minLength}`
            );
            if (!vessels) return;
            setMarineVessels(
                tagWithDataSourceType(vessels, DataSourceType.MarineTraffic)
            );
        },
        [getJson, setMarineVessels]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateMarineTraffic = useCallback(
        debounce(
            (query: MarineTrafficQuery) => updateMarineTraffic(query),
            500
        ),
        []
    );

    const query: MarineTrafficQuery = useMemo(() => {
        const getMinSizeForZoom = () => {
            const minSizeLargeVessels = 200;
            const minSizeMediumVessels = 100;
            if (zoom.zoomLevel >= 10) return 0;
            if (zoom.zoomLevel >= 8) return minSizeMediumVessels;
            return minSizeLargeVessels;
        };

        return {
            distanceInKm: requestArea.radius,
            lat: requestArea.lat,
            lon: requestArea.lon,
            minLength: getMinSizeForZoom(),
        };
    }, [requestArea.lat, requestArea.lon, requestArea.radius, zoom.zoomLevel]);

    if (enabled && shouldLoad) {
        debouncedUpdateMarineTraffic(query);
    }

    useEffect(() => {
        if (!enabled) {
            setMarineVessels([]);
            return;
        }

        const pollInterval = 5000; //5sec
        const timerId = setInterval(
            () => updateMarineTraffic(query),
            pollInterval
        );

        return () => clearInterval(timerId);
    }, [
        enabled,
        debouncedUpdateMarineTraffic,
        zoom.zoomLevel,
        updateMarineTraffic,
        requestArea.radius,
        requestArea.lat,
        requestArea.lon,
        shouldLoad,
        query,
    ]);

    return marineVessels;
}
