import {
    Checkbox,
    createStyles,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    makeStyles,
    Popover,
    TextField,
    Theme,
    Typography,
} from "@material-ui/core";
import HeliMediumIcon from "../../icons-v2/HeliLightLight.png";
import HeliHeavyIcon from "../../icons-v2/HeliHeavyLight.png";
import DayIcon from "../../icons-v2/DayLight.png";
import NightIcon from "../../icons-v2/NightLight.png";
import SmileyIcon from "../../icons-v2/HappyLight.png";
import FrownIcon from "../../icons-v2/SadLight.png";
import NeutralSwitch from "../helideck/NeutralSwitch";
import mapStyles from "./mapStyles/mapStyles";
import { useSettings } from "../../contexts/SettingsContext";
import {
    allDataSourceTypes,
    DataSourcesService,
} from "../../hooks/useDataSources";
import useSystemStatus from "../../hooks/useSystemStatus";
import { formatRelative, parseISO } from "date-fns";
import msalInstance from "../../utils/authProvider";
import { useState } from "react";
import About from "./About";
import {
    useAppInsightsContext,
    useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerRoot: {
            width: 250,
        },
        list: {
            width: "100%",
            maxWidth: 250,
            backgroundColor: theme.palette.background.paper,
        },
    })
);

export default function MenuDrawer({
    open,
    toggle,
    dataSourcesService,
}: {
    open: boolean;
    toggle: (
        open: boolean
    ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
    dataSourcesService: DataSourcesService;
}) {
    const classes = useStyles();
    const {
        operationService,
        mapStyleService,
        showHeightBar,
        toggleHeightBar,
        distanceUnit,
        toggleDistanceUnit,
        showDemoController,
        toggleDemoController,
        zoom,
        setZoomPreset,
    } = useSettings();
    const systemStatus = useSystemStatus();
    const [aboutAnchorEl, setAboutAnchorEl] = useState<HTMLElement | null>(
        null
    );
    const appInsights = useAppInsightsContext();
    const trackMapStyleClicked = useTrackEvent(
        appInsights,
        "Mapstyle",
        mapStyleService.mapStyle
    );

    return (
        <Drawer anchor="left" open={open} onClose={toggle(false)}>
            <div className={classes.drawerRoot} role="presentation">
                <List
                    subheader={<ListSubheader>Data Layers</ListSubheader>}
                    className={classes.list}
                >
                    {allDataSourceTypes.map((ds) => (
                        <ListItem key={`datasource-menu-button-${ds}`}>
                            <ListItemIcon>
                                {dataSourcesService.getIcon(ds)}
                            </ListItemIcon>
                            <ListItemText
                                id={`datasource-menu-label-${ds}`}
                                primary={dataSourcesService.getDisplayName(ds)}
                            />
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={() =>
                                        dataSourcesService.toggle(ds)
                                    }
                                    checked={dataSourcesService.isDisplayed(ds)}
                                    inputProps={
                                        {
                                            "aria-labelledby": `datasource-menu-label-${ds}`,
                                            "data-sample-id": `DataSourceMenu`,
                                            "data-sample-type": ds,
                                            "data-sample-state":
                                                !dataSourcesService.isDisplayed(
                                                    ds
                                                ),
                                        } as any
                                    }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List
                    subheader={<ListSubheader>Settings</ListSubheader>}
                    className={classes.list}
                >
                    <ListItem>
                        <ListItemIcon>
                            {operationService.isHeavy() ? (
                                <img src={HeliHeavyIcon} alt="heli heavy" />
                            ) : (
                                <img src={HeliMediumIcon} alt="heli light" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            id="list-aircraft"
                            primary={operationService.aircraftClass}
                        />
                        <ListItemSecondaryAction>
                            <NeutralSwitch
                                checked={operationService.isHeavy()}
                                onChange={operationService.toggleAircraftClass}
                                inputProps={
                                    {
                                        "data-sample-id": "AircraftClass",
                                        "data-sample-state":
                                            !operationService.isHeavy(),
                                    } as any
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            {operationService.isNight() ? (
                                <img src={NightIcon} alt="night" />
                            ) : (
                                <img src={DayIcon} alt="day" />
                            )}
                        </ListItemIcon>
                        <ListItemText
                            id="list-time"
                            primary={operationService.operationTime}
                        />
                        <ListItemSecondaryAction>
                            <NeutralSwitch
                                checked={operationService.isNight()}
                                onChange={operationService.toggleOperationTime}
                                inputProps={
                                    {
                                        "data-sample-id": "OperationTime",
                                        "data-sample-state":
                                            !operationService.isNight(),
                                    } as any
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            id="list-height-bar"
                            primary={"Show altitude scale"}
                        />
                        <ListItemSecondaryAction>
                            <NeutralSwitch
                                checked={showHeightBar}
                                onChange={toggleHeightBar}
                                inputProps={
                                    {
                                        "data-sample-id": "AltitudeScale",
                                        "data-sample-state": !showHeightBar,
                                    } as any
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    {/* <ListItem>
                        <ListItemIcon>
                            <DownloadIcon stroke={iconColor} fill={iconColor} />
                        </ListItemIcon>
                        <ListItemText
                            id="list-regions"
                            primary={
                                storageRegionsService.displayStorageRegions
                                    ? "Regions on"
                                    : "Regions off"
                            }
                        />
                        <ListItemSecondaryAction>
                            <NeutralSwitch
                                checked={
                                    storageRegionsService.displayStorageRegions
                                }
                                onChange={() =>
                                    storageRegionsService.setDisplayStorageRegions(
                                        !storageRegionsService.displayStorageRegions
                                    )
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem> */}
                </List>
                <Divider />
                <List
                    subheader={<ListSubheader>Crosshair unit</ListSubheader>}
                    className={classes.list}
                >
                    <ListItem>
                        <ListItemText
                            id="list-crosshair-bar"
                            primary={distanceUnit}
                        />
                        <ListItemSecondaryAction>
                            <NeutralSwitch
                                checked={distanceUnit === "nmi"}
                                onChange={toggleDistanceUnit}
                                inputProps={
                                    {
                                        "data-sample-id":
                                            "CrosshairDistanceUnit",
                                        "data-sample-state": distanceUnit,
                                    } as any
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <Divider />
                <List
                    subheader={<ListSubheader>Map styles</ListSubheader>}
                    className={classes.list}
                >
                    {mapStyles.map((x: any, i: number) => (
                        <ListItem
                            data-sample-id="MapStyle"
                            data-sample-style={x.name}
                            key={x.name}
                            id={`map-style-${i}`}
                            selected={x.name === mapStyleService.mapStyle.name}
                            onClick={() => {
                                mapStyleService.setMapStyle(x);
                                trackMapStyleClicked(x);
                            }}
                        >
                            <ListItemText id="list-light" primary={x.name} />
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List
                    subheader={<ListSubheader>Zoom levels</ListSubheader>}
                    className={classes.list}
                >
                    {zoom.zoomPresets.map((x: number, i: number) => (
                        <ListItem key={`preset${i}`}>
                            <ListItemText>Z{i + 1}</ListItemText>
                            <ListItemSecondaryAction>
                                <TextField
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min:
                                                i === 0
                                                    ? 6
                                                    : zoom.zoomPresets[i - 1],
                                            max:
                                                i ===
                                                zoom.zoomPresets.length - 1
                                                    ? 14
                                                    : zoom.zoomPresets[i + 1],
                                            defaultValue: x,
                                        },
                                    }}
                                    onChange={(x: any) =>
                                        setZoomPreset(i, x.target.value)
                                    }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List
                    subheader={<ListSubheader>User</ListSubheader>}
                    className={classes.list}
                >
                    <ListItem
                        button
                        onClick={() => {
                            msalInstance.logoutRedirect();
                        }}
                    >
                        <ListItemText>Sign out</ListItemText>
                    </ListItem>
                </List>
                <Divider />
                <List
                    subheader={<ListSubheader>System</ListSubheader>}
                    className={classes.list}
                >
                    <ListItem>
                        <Typography variant="caption">
                            AeroMet IFIS v{process.env.REACT_APP_VERSION}
                        </Typography>
                    </ListItem>
                    <ListItem
                        onClick={() => {
                            setAboutAnchorEl(window.document.body);
                        }}
                    >
                        <ListItemText>About</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            {systemStatus?.healthy ? (
                                <img src={SmileyIcon} alt="smile" />
                            ) : (
                                <img src={FrownIcon} alt="frown" />
                            )}
                        </ListItemIcon>
                        <ListItemText>
                            {systemStatus.healthy
                                ? "System is OK"
                                : `System degradation since ${formatRelative(
                                      parseISO(systemStatus.startTime),
                                      new Date()
                                  )}`}
                        </ListItemText>
                    </ListItem>
                </List>
                <List
                    subheader={
                        <ListSubheader>Show demo controll</ListSubheader>
                    }
                    className={classes.list}
                >
                    <ListItem>
                        <ListItemText
                            id="show-demo-controller"
                            primary={showDemoController}
                        />
                        <ListItemSecondaryAction>
                            <NeutralSwitch
                                checked={showDemoController}
                                onChange={toggleDemoController}
                                inputProps={
                                    {
                                        "data-sample-id": "ShowDemoController",
                                        "data-sample-state": showDemoController,
                                    } as any
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
                <Divider />
            </div>
            <Popover
                id="aboutPopover"
                open={Boolean(aboutAnchorEl)}
                anchorEl={aboutAnchorEl}
                onClose={() => {
                    setAboutAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                }}
            >
                <About />
            </Popover>
        </Drawer>
    );
}
