import { DataSourceType } from "../hooks/useDataSources";

// TODO: inject
export const baseUrl = "https://ifis-lmc-api-dev-app.azurewebsites.net";
// export const baseUrl = "https://localhost:5001";

export type BasicSpatialQuery = {
    km: number;
    lat: number;
    lon: number;
};

export type LocationQuery = {
    km: number;
    lat: number;
    lon: number;
    hasHeliDecks?: boolean;
    minQuality?: string;
};

export const tagWithDataSourceType = (
    source: any[],
    dataSourceType: DataSourceType
) => {
    return source.map((x) => ({ ...x, dataSourceType }));
};
