import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AircraftClassSwitch from "../AircraftClassSwitch";
import OperationTimeSwitch from "../OperationTimeSwitch";

export default function HelideckConfig() {
    return (
        <Paper variant="outlined" style={{ padding: 8, marginBottom: 12 }}>
            <Grid container alignItems="flex-end">
                <Grid item xs={6}>
                    <AircraftClassSwitch />
                </Grid>
                <Grid item xs={6}>
                    <OperationTimeSwitch />
                </Grid>
            </Grid>
        </Paper>
    );
}
