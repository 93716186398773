import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import { useSettings } from "../../contexts/SettingsContext";

const findColor = (status: number) => {
    switch (status) {
        case 1:
            return "green";
        case 2:
            return "yellow";
        case 3:
            return "red";
        default:
            return "red";
    }
};

const HelideckFavoriteContainer = ({ children }: any) => {
    return (
        <Paper
            elevation={3}
            style={{
                opacity: "70%",
                width: 400,
                position: "absolute",
                right: 0,
            }}
        >
            {children}
        </Paper>
    );
};

export default function HelideckFavorites({ onSourceSelected }: any) {
    const { favoritesService, operationService } = useSettings();

    return (
        <TableContainer component={HelideckFavoriteContainer}>
            <Table>
                <TableBody>
                    {favoritesService.favorites.map(
                        ({ siteReport, helideckReport }) => (
                            <TableRow
                                key={helideckReport.helideckDetails.id}
                                data-testid="favoriteRow"
                            >
                                <TableCell component="th" scope="row">
                                    {helideckReport.helideckDetails.id}
                                </TableCell>
                                <TableCell align="right">
                                    <Fab
                                        onClick={() =>
                                            onSourceSelected(siteReport)
                                        }
                                        style={{
                                            backgroundColor: findColor(
                                                operationService.isHeavy()
                                                    ? operationService.isNight()
                                                        ? helideckReport.statusHeavyNight
                                                        : helideckReport.statusHeavyDay
                                                    : operationService.isNight()
                                                    ? helideckReport.statusMediumNight
                                                    : helideckReport.statusMediumDay
                                            ),
                                        }}
                                    >
                                        {" "}
                                    </Fab>
                                </TableCell>

                                <TableCell align="right">
                                    <Fab
                                        onClick={() =>
                                            favoritesService.removeFromFavorites(
                                                helideckReport
                                            )
                                        }
                                    >
                                        X
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
