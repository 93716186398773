import { useCallback, useEffect } from "react";
import { Map } from "mapbox-gl";
import controlZones from "../../../../utils/controlZones/control_zones.json";
import controlZonesWithInteriorRings from "../../../../utils/controlZones/control_zones_with_interior_rings.json";

export default function useControlZonesSource(
    map: Map | null,
    loadedMapStyle: string | null
) {
    const onClick = useCallback((e: any) => {}, []);

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const sourceName = "control-zones-outer";
        if (map.getSource(sourceName)) {
            return;
        }

        map.addSource(sourceName, {
            type: "geojson",
            data: controlZones as any,
        });

        map.addLayer({
            id: sourceName + "-fill",
            type: "fill",
            source: sourceName,
            layout: {},
            paint: {
                "fill-color": "red",
                "fill-opacity": 0.2,
            },
            maxzoom: 10,
        });

        map.addLayer({
            id: sourceName + "thin-outline",
            type: "line",
            source: sourceName,
            layout: {},
            paint: {
                "line-color": "#000",
                "line-width": 1,
            },
            maxzoom: 10,
        });

        map.addLayer({
            id: sourceName + "thick-outline",
            type: "line",
            source: sourceName,
            layout: {},
            paint: {
                "line-color": "#000",
                "line-width": 2,
            },
            minzoom: 10,
        });

        const sourceNameInner = "control-zones-inner";
        if (map.getSource(sourceNameInner)) {
            return;
        }

        map.addSource(sourceNameInner, {
            type: "geojson",
            data: controlZonesWithInteriorRings as any,
        });

        map.addLayer({
            id: sourceNameInner + "-fill",
            type: "fill",
            source: sourceNameInner,
            paint: {
                "fill-color": "#000",
                "fill-opacity": 0.3,
            },
        });
    }, [map, loadedMapStyle, onClick]);
}
