import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LabelValue from "../core/LabelValue";
import { CardHeader, Grid } from "@material-ui/core";
import PressureIcon from "../../icons-v2/PressureLight.png";
import { PressureReport } from "../../api/ifis";

export default function PressureCard({
    pressure,
}: {
    pressure: PressureReport | null | undefined;
}) {
    if (pressure == null) {
        return null;
    }

    return (
        <Card variant="outlined">
            <CardHeader
                title="Pressure"
                avatar={<img src={PressureIcon} alt="pressure" />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="QNH"
                            value={pressure.qnh}
                            slashesWhenEmpty
                            unitKey={"pressure"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="QFE"
                            value={pressure.qfe}
                            slashesWhenEmpty
                            unitKey={"pressure"}
                        ></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
