import { Switch, withStyles } from "@material-ui/core";

const NeutralSwitch = withStyles(({ palette }) => ({
    switchBase: {
        color: palette.secondary.main,
        "&$checked": {
            color: palette.secondary.main,
        },
        "&$checked + $track": {
            backgroundColor: "white",
        },
    },
    checked: {},
    track: {},
}))(Switch);

export default NeutralSwitch;
