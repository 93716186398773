import { useState, useEffect, useCallback } from "react";
import { useApi } from "../contexts/ApiContext";
import { getDistance } from "../utils/geoCalculations";

export type AggregatedSiteReportDto = {
    wind: number;
    gust: number;
    airTemperature: number;
    dewPoint: number;
    qnh: number;
    visibility: number;
    cloudHeight: number;
    cloudCover: string;
};

export default function useAggregatedSiteReport({ km, lat, lon }: any) {
    const [
        aggregatedSiteReport,
        setAggregatedSiteReport,
    ] = useState<AggregatedSiteReportDto | null>(null);
    const pollInterval = 5000;
    const { getJson } = useApi();
    const [fetchPosition, setFetchPosition] = useState({ km, lat, lon });

    useEffect(() => {
        //update fetch position if distance between points is more than 5 km
        setFetchPosition((x) => {
            if (x != null && getDistance(x.lat, x.lon, lat, lon) < 5) {
                return x;
            }

            return { lat, lon, km };
        });
    }, [km, lat, lon]);

    const getAggregatedSiteReport = useCallback(
        () =>
            getJson(
                `/meteorology/site-report/aggregated?km=${fetchPosition.km}&lat=${fetchPosition.lat}&lon=${fetchPosition.lon}`
            ).then(setAggregatedSiteReport),
        [getJson, fetchPosition]
    );

    useEffect(() => {
        const timerId = setInterval(() => {
            getAggregatedSiteReport();
        }, pollInterval);

        getAggregatedSiteReport();

        return () => {
            clearInterval(timerId);
            setAggregatedSiteReport(null);
        };
    }, [getAggregatedSiteReport]);

    return aggregatedSiteReport;
}
