import { useCallback, useRef } from "react";
import distance from "@turf/distance";
import { point } from "@turf/helpers";
import { getDistance } from "../../../../utils/geoCalculations";
import { WebcamDto } from "../../../../api/ifis";

export default function useDistrict(
    map: mapboxgl.Map | null,
    roadCameras: WebcamDto[]
) {
    const districtSearchCallbackRef = useRef<
        (
            lon: number,
            lat: number
        ) => {
            roadCameras: WebcamDto[];
            totalCount: number;
        }
    >((lon: number, lat: number) => {
        return {
            roadCameras: [],
            totalCount: 0,
        };
    });

    const getDistancePerPixel = (map: any) => {
        const southWest = map.getBounds().getSouthWest();
        const southEast = map.getBounds().getSouthEast();

        const distance = getDistance(
            southEast.lat,
            southEast.lng,
            southWest.lat,
            southWest.lng
        );

        return distance / window.innerWidth;
    };

    districtSearchCallbackRef.current = useCallback(
        (lon: number, lat: number) => {
            const nearbyFilter = (
                objectLon: number,
                objectLat: number,
                distanceToCompare: number
            ) => {
                return (
                    distance(
                        point([lon, lat]),
                        point([objectLon, objectLat])
                    ) <= distanceToCompare
                );
            };

            const distanceOnScreen = getDistancePerPixel(map) * 40;
            const nearRoadCameras = roadCameras?.filter((x) =>
                nearbyFilter(x.lon, x.lat, distanceOnScreen)
            );

            let total = 0;
            total += nearRoadCameras?.length ?? 0;

            return {
                roadCameras: nearRoadCameras,
                totalCount: total,
            };
        },
        [map, roadCameras]
    );

    return { districtSearchCallbackRef };
}
