import { useState } from "react";
import mapStyles from "../components/menu/mapStyles/mapStyles";
import useLocalStorage from "./useLocalStorage";

type MapStyleType = {
    name: string;
    url: string;
    privateProviderUrl: string;
    subdomains: string;
    attribution: string;
    maxZoom: number;
};

export type MapStyleHookType = {
    mapStyle: MapStyleType;
    setMapStyle: (mapstyle: MapStyleType) => void;
};

export default function useMapStyle(): MapStyleHookType {
    const [saveObject, getObject] = useLocalStorage("map-style");
    const [mapStyle, setMapStyleState] = useState(getObject() || mapStyles[0]);

    const setMapStyle = (mapStyle: MapStyleType) => {
        setMapStyleState(mapStyle);
        saveObject(mapStyle);
    };

    return {
        mapStyle,
        setMapStyle,
    };
}
