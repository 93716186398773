import { Button } from "@material-ui/core";
import { useSettings } from "../../contexts/SettingsContext";
import { makeStyles } from "@material-ui/core/styles";
import PlusIcon from "../../icons-v2/Plus.png";
import MinusIcon from "../../icons-v2/Minus.png";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "auto",
        left: "auto",
        bottom: 8,
        right: 8,
        display: "flex",
        flexDirection: "column",
        paddingTop: 8,
    },
    button: {
        border: "2px solid",
        color: "black",
        borderColor: "black",
        marginTop: -2,
        borderRadius: 0,
        height: 40,
        width: 40,
        minWidth: 40,
        padding: 0,
    },
}));

const ZoomControl = () => {
    const { zoom } = useSettings();
    const classes = useStyles();

    const nextZoomPreset = Math.min(
        zoom.zoomPresets.length - 1,
        zoom.currZoomPreset + 1
    );
    const prevZoomPreset = Math.max(0, zoom.currZoomPreset - 1);
    return (
        <div className={classes.root}>
            <Button
                onClick={() =>
                    zoom.setZoomLevel(zoom.zoomPresets[nextZoomPreset])
                }
                className={classes.button}
                variant="contained"
            >
                <img src={PlusIcon} alt="zoom in" />
            </Button>
            <Button
                onClick={() =>
                    zoom.setZoomLevel(zoom.zoomPresets[prevZoomPreset])
                }
                className={classes.button}
                variant="contained"
                disableElevation
            >
                <img src={MinusIcon} alt="zoom out" />
            </Button>
        </div>
    );
};

export default ZoomControl;
