import HeliMediumIcon from "../../icons-v2/HeliLightDark.png";
import HeliHeavyIcon from "../../icons-v2/HeliHeavyDark.png";
import IconSwitch from "./IconSwitch";
import { useSettings } from "../../contexts/SettingsContext";

export default function AircraftClassSwitch({ ...others }: any) {
    const { operationService } = useSettings();
    return (
        <IconSwitch
            leftIcon={HeliMediumIcon}
            rightIcon={HeliHeavyIcon}
            isRight={operationService.isHeavy}
            toggle={operationService.toggleAircraftClass}
            {...others}
        />
    );
}
