import React from "react";
import useSiteReportDetails from "../../hooks/useSiteReportDetails";
import SiteReport from "../siteReport/SiteReport";
import { SiteReportCompactDto } from "../../api/ifis";

export default function SiteReportDetails({
    sourceDetails,
}: {
    sourceDetails: SiteReportCompactDto;
}) {
    const siteReport = useSiteReportDetails(sourceDetails);

    if (siteReport == null) return <p>Loading...</p>;

    return <SiteReport siteReport={siteReport} />;
}
