import { Card, CardContent, Grid } from "@material-ui/core";
import LabelValue from "../core/LabelValue";
import useAggregatedSiteReport from "../../hooks/useAggregatedSiteReport";
import ZoomPresets from "./ZoomPresets";
import { getTimes } from "suncalc";
import { format } from "date-fns";

type AggregatedAreaDetailsProps = {
    lat: number;
    lon: number;
};

export default function AggregatedAreaDetails({
    lat,
    lon,
}: AggregatedAreaDetailsProps) {
    const aggregatedSiteReport = useAggregatedSiteReport({
        lat,
        lon,
        km: 10,
    });
    const times = getTimes(new Date(), lat, lon);

    return (
        <div>
            <Card
                variant="outlined"
                style={{
                    height: window.innerHeight * 0.3,
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                    left: 0,
                }}
            >
                <CardContent style={{ padding: 8 }}>
                    <Grid container>
                        <Grid container item xs={10}>
                            <Grid item xs={12}>
                                <LabelValue
                                    label="Sunset / dusk"
                                    value={`${
                                        isNaN(times.sunset.getTime())
                                            ? "never"
                                            : format(times.sunset, "HH:mm")
                                    } / ${
                                        isNaN(times.dusk.getTime())
                                            ? "never"
                                            : format(times.dusk, "HH:mm")
                                    }`}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="Wind Speed"
                                    value={aggregatedSiteReport?.wind}
                                    slashesWhenEmpty
                                    unitKey={"windSpeed"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="Gust Speed"
                                    value={aggregatedSiteReport?.gust}
                                    slashesWhenEmpty
                                    unitKey={"windSpeed"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="Air temp"
                                    value={aggregatedSiteReport?.airTemperature}
                                    slashesWhenEmpty
                                    unitKey={"temp"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="Dew point"
                                    value={aggregatedSiteReport?.dewPoint}
                                    slashesWhenEmpty
                                    unitKey={"temp"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="QNH"
                                    value={aggregatedSiteReport?.qnh}
                                    slashesWhenEmpty
                                    unitKey={"pressure"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="Visibility"
                                    value={aggregatedSiteReport?.visibility}
                                    slashesWhenEmpty
                                    unitKey={"visibility"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="Cloud Height"
                                    value={aggregatedSiteReport?.cloudHeight}
                                    slashesWhenEmpty
                                    unitKey={"cloudHeight"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={6}>
                                <LabelValue
                                    label="Cloud Cover"
                                    value={aggregatedSiteReport?.cloudCover}
                                    slashesWhenEmpty
                                ></LabelValue>
                            </Grid>
                        </Grid>
                        <Grid container item xs={2} justify="flex-end">
                            <Grid item>
                                <ZoomPresets />
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}
