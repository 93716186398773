import {
    FeatureCollection,
    GeoJsonProperties,
    Geometry,
    Feature,
} from "geojson";

export const emptyFeatureCollection = (): FeatureCollection<
    Geometry,
    GeoJsonProperties
> => {
    return {
        type: "FeatureCollection",
        features: [],
    };
};

export const mapToFeatureCollection = (
    markers: any[],
    idKey: string,
    additionalProps?: string[]
): FeatureCollection<Geometry, GeoJsonProperties> => ({
    type: "FeatureCollection",
    features: markers.map((x) => {
        const feature: Feature<Geometry> = {
            id: x[idKey],
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [x.lon, x.lat],
            },
            properties: {
                id: x[idKey],
            },
        };
        if (additionalProps) {
            additionalProps.forEach((prop) => {
                if (!feature.properties) feature.properties = [];
                feature.properties[prop] = x[prop];
            });
        }
        return feature;
    }),
});
