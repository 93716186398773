import { Map, SymbolLayer, SymbolLayout, SymbolPaint } from "mapbox-gl";

type GeoJsonSymbolSourceOptions = {
    sourceName: string;
    layerPrefix?: string;
    icon?: string;
    sdf?: boolean;
    layout?: SymbolLayout;
    paint?: SymbolPaint;
    size?: number;
    width?: number;
    height?: number;
    filter?: any[] | undefined;
    onClick?: (e: any) => void;
};

export function addSymbolLayer(
    map: Map,
    {
        sourceName,
        layerPrefix = "",
        icon,
        sdf,
        layout,
        paint,
        size = 24,
        width,
        height,
        filter,
        onClick = () => {},
    }: GeoJsonSymbolSourceOptions
) {
    if (map == null) {
        return;
    }

    const layerName = sourceName + layerPrefix + "-symbol-layer";
    map.on("click", layerName, onClick);

    if (icon != null) {
        const iconName = addIcon(
            map,
            sourceName,
            icon,
            size,
            width,
            height,
            sdf,
            layerPrefix
        );
        layout = { ...layout, "icon-image": iconName };
    }

    let layer: SymbolLayer = {
        id: layerName,
        type: "symbol",
        source: sourceName,
        paint: paint || {},
        layout: layout || {},
    };
    if (filter) {
        layer = { filter, ...layer };
    }
    map.addLayer(layer);

    map.on("mouseenter", layerName, function () {
        map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", layerName, function () {
        map.getCanvas().style.cursor = "";
    });

    return layerName;
}

export function addIcon(
    map: Map,
    sourceName: string,
    icon: string,
    size: number,
    width?: number,
    height?: number,
    sdf: boolean | undefined = undefined,
    prefix: string = ""
) {
    return addRectIcon(
        map,
        sourceName,
        icon,
        width ?? size,
        height ?? size,
        sdf,
        prefix
    );
}

export function addRectIcon(
    map: Map,
    sourceName: string,
    icon: string,
    width: number,
    height: number,
    sdf: boolean | undefined = undefined,
    prefix: string = ""
) {
    const iconName = sourceName + prefix + "-icon";
    const img = new Image(width, height);
    img.onload = () => map.addImage(iconName, img, { sdf });
    img.src = icon;
    return iconName;
}
