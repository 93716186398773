import CloudCard from "./CloudCard";
import WeatherCard from "./WeatherCard";
import PressureCard from "./PressureCard";
import TemperatureAndHumidityCard from "./TemperatureAndHumidityCard";
import VisibilityCard from "./VisibilityCard";
import AverageWindCard from "./AverageWindCard";
import SiteDetailsCard from "./SiteDetailsCard";
import { Card, CardHeader } from "@material-ui/core";
import { SiteReportDto } from "../../api/ifis";

export default function WeatherReport({
    siteReport,
}: {
    siteReport: SiteReportDto;
}) {
    return (
        <div>
            <Card variant="outlined">
                <CardHeader
                    title={`${siteReport.name} - ${siteReport.source}`}
                />
            </Card>
            <PressureCard pressure={siteReport.pressure} />
            <TemperatureAndHumidityCard
                temperatureAndHumidity={siteReport.temperatureAndHumidity}
            />
            <AverageWindCard wind={siteReport.wind} />
            <WeatherCard weather={siteReport.weather} />
            <VisibilityCard visibility={siteReport.visibility} />
            <CloudCard cloud={siteReport.cloud} />
            <SiteDetailsCard
                lat={siteReport.lat}
                lon={siteReport.lon}
                elevation={siteReport.elevation}
                source={siteReport.source}
            />
        </div>
    );
}
