import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { altitudeColorStops, groundColor } from "../utils/altitudeColors";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scaleGrid: {
            position: "fixed",
            top: "auto",
            left: "auto",
            right: 0,
            // bottom: 64,
            width: "min(80%, 800px)",
            background: "none",
            pointerEvents: "none",
        },
    })
);

const AltitudeColorScale = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.scaleGrid}>
            <Grid item xs={2} style={{ backgroundColor: groundColor }}>
                <Typography variant="caption" component="p">
                    Ground
                </Typography>
            </Grid>
            {altitudeColorStops.slice(0, -1).map((stop, index) => (
                <Grid
                    item
                    key={"color-scale-" + index}
                    xs={2}
                    style={{
                        background: `linear-gradient(to right, ${stop.color}, ${
                            altitudeColorStops[index + 1].color
                        })`,
                    }}
                >
                    <Typography
                        variant="caption"
                        component="p"
                        align="right"
                        color="textSecondary"
                    >
                        {altitudeColorStops[index + 1].altitude / 1000}kft
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default AltitudeColorScale;
