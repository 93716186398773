import { useState } from "react";
import storageRegions, {
    StorageRegion,
} from "../utils/storageRegions/storageRegions";
import useTileStorage from "./useTileStorage";
import zoomLevels from "../utils/zoomLevels";

export type StorageRegionsHookType = {
    regions: StorageRegion[];
    selectedRegion: StorageRegion | undefined;
    selectedRegionStatus: string | undefined;
    selectRegionForStorage: (region: StorageRegion) => void;
    displayStorageRegions: boolean;
    setDisplayStorageRegions: (value: boolean) => void;
};

export default function useStorageRegions(): StorageRegionsHookType {
    const [regions] = useState(storageRegions);
    const [selectedRegion, setSelectedRegion] = useState<StorageRegion>();
    const [selectedRegionStatus, setSelectedRegionStatus] = useState<string>();
    const [displayStorageRegions, setDisplayStorageRegions] = useState(false);
    const { clearTileStore } = useTileStorage();

    const lon2tile = (lon: number, zoom: number) => {
        return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
    };

    const lat2tile = (lat: number, zoom: number) => {
        return Math.floor(
            ((1 -
                Math.log(
                    Math.tan((lat * Math.PI) / 180) +
                        1 / Math.cos((lat * Math.PI) / 180)
                ) /
                    Math.PI) /
                2) *
                Math.pow(2, zoom)
        );
    };

    // todo: reimplement to use local mbtiles database
    const storeRegionTiles = async (region: [number[], number[]]) => {
        const cachingPromises: any[] = [];
        zoomLevels.forEach((z: number) => {
            const top_tile = lat2tile(region[1][0], z);
            const left_tile = lon2tile(region[0][1], z);
            const bottom_tile = lat2tile(region[0][0], z);
            const right_tile = lon2tile(region[1][1], z);
            for (let x = left_tile; x <= right_tile; x++) {
                for (let y = top_tile; y <= bottom_tile; y++) {
                    // let i = (x - left_tile) * (y - top_tile) + (x - left_tile);
                    // const tileUrl = storageTileLayer
                    //     .getTileUrl({ x, y, z } as L.Coords)
                    //     .replace("zoom=NaN", `zoom=${z}`); // dirty fix for stateful zoom calculation in leaflet TileLayer
                    // cachingPromises[i] = getTile(tileUrl, accessToken).then(
                    //     (blob) => {
                    //         storeTile(x, y, z, blob);
                    //     }
                    // );
                }
            }
        });
        return Promise.all(cachingPromises);
    };

    const selectRegionForStorage = (region: StorageRegion) => {
        if (region === selectedRegion && selectedRegionStatus !== "error")
            return;
        setSelectedRegion(region);
        setSelectedRegionStatus("saving");

        clearTileStore();

        storeRegionTiles(region.latlngs)
            .catch(() => {
                setSelectedRegionStatus("error");
            })
            .then(() => {
                setSelectedRegionStatus("saved");
            });
    };

    return {
        regions,
        selectedRegion,
        selectedRegionStatus,
        selectRegionForStorage,
        displayStorageRegions,
        setDisplayStorageRegions,
    };
}
