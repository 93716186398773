import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from "react";
import msalInstance, { tokenRequest } from "../utils/authProvider";

const baseUrl = "https://ifis-lmc-api-dev-app.azurewebsites.net";
// const baseUrl = "https://localhost:5001";

type ServerConnectionStatus =
    | "Ok"
    | "Server error"
    | "Network error"
    | undefined;

type ApiContextValue = {
    averageDuration: number | undefined;
    getJson: (url: string) => Promise<any>;
    postJson: (url: string, json: string) => Promise<any>;
    lastConnectionStatus: ServerConnectionStatus;
};

const ApiContext = createContext<ApiContextValue>({} as ApiContextValue);

const getAccessToken = async () => {
    try {
        return await msalInstance.acquireTokenSilent(tokenRequest);
    } catch (ex) {
        await msalInstance.acquireTokenRedirect(tokenRequest);
        throw ex;
    }
};

const getWithAccessToken = async (path: string) => {
    const token = await getAccessToken();

    return await fetch(`${baseUrl}${path}`, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + token.accessToken,
            "Content-Type": "application/json",
        },
    });
};

const postWithAccessToken = async (path: string, body: string) => {
    const token = await await getAccessToken();

    return await fetch(`${baseUrl}${path}`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + token.accessToken,
            "Content-Type": "application/json",
        },
        body,
    });
};

export const ApiProvider = ({ children }: { children?: ReactNode }) => {
    const [, setLastTenDurations] = useState<number[]>([]);
    const [averageDuration, setAverageDuration] = useState<number>();
    const [lastConnectionStatus, setLastConnectionStatus] =
        useState<ServerConnectionStatus>();

    const updateDuration = useCallback((duration: number) => {
        setLastTenDurations((previousDurations) => {
            if (previousDurations.length >= 50) {
                previousDurations.shift();
            }

            const durations = [...previousDurations, duration];

            setAverageDuration(
                durations.reduce((x, y) => x + y, 0) / durations.length
            );

            return durations;
        });
    }, []);

    const getJson = useCallback(
        async (path: string): Promise<any> => {
            const sendTime = new Date().getTime();

            try {
                const response = await getWithAccessToken(path);
                setLastConnectionStatus(
                    response.status === 500 ? "Server error" : "Ok"
                );

                updateDuration(new Date().getTime() - sendTime);

                return await response.json();
            } catch (e) {
                setLastConnectionStatus("Network error");
            }
        },
        [updateDuration]
    );

    const postJson = async (path: string, json: string): Promise<any> => {
        const response = await postWithAccessToken(path, json);
        return response.status;
    };

    return (
        <ApiContext.Provider
            value={{
                getJson,
                postJson,
                averageDuration,
                lastConnectionStatus,
            }}
        >
            {children}
        </ApiContext.Provider>
    );
};

export const useApi = () => useContext(ApiContext);
