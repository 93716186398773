import React, { useState, useEffect } from "react";
import { getDistance } from "../../utils/geoCalculations";
import { useSettings } from "../../contexts/SettingsContext";
import { useRef } from "react";
import zoomLevels from "../../utils/zoomLevels";
import useSiteReportSource from "./sources/hooks/useSiteReportsSource";
import useAircraftSource from "./sources/hooks/useAircraftSource";
import useObstaclesSource from "./sources/hooks/useObstacleSource";
import useRoadCameraSource from "./sources/hooks/useCameraSource";
import useMarineSource from "./sources/hooks/useMarineSource";
import useLightningSource from "./sources/hooks/useLightningSource";
import useHelideckSource from "./sources/hooks/useHelideckSource";
import useAreaCircleSource from "./sources/hooks/useAreaCircle";

import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import useControlZonesSource from "./sources/hooks/useControlZonesSource";
import { DistrictType } from "./DistrictPopup";
import useDistrict from "./sources/hooks/useDistrict";
import { DataSource } from "../../api/ifis";
import usePowerlineSource from "./sources/hooks/usePowerlineSource";
import { GeoArea } from "../../hooks/useViewArea";
import useTracking from "./sources/hooks/useTracking";
import useUserLocationSource from "./sources/hooks/useUserLocationSource";
import Compass from "../Compass";
import Crosshair from "./Crosshair";
import useTrackingDemo from "./sources/hooks/useTrackingDemo";
import useUserLocationSourceDemo from "./sources/hooks/useUserLocationSourceDemo";
import DemoController, { TourStatus } from "./DemoController";
import { useHistory, useParams } from "react-router";
import { parseCoordinatesWithZoom } from "../../utils/coordinates";
import { DataSourceType } from "../../hooks/useDataSources";

mapboxgl.accessToken =
    "pk.eyJ1IjoiZW1pbGJvcm93aWVjIiwiYSI6ImNrbzQ5MWFkbTFneXUybms0YjJtMjU5NzIifQ.4O9WhvqwwwUmmNpxZfPvAA";

type MapProps = {
    viewLon: number;
    viewLat: number;
    siteReports: any[];
    siteReportsWithHelidecks: any[];
    aircrafts: any[];
    marineVessels: any[];
    lightning: any[];
    webcams: any[];
    areObstaclesOn: boolean;
    mapStyle: string;
    isTracking: boolean;
    isTrackingHeading: boolean;
    stopTracking: () => void;
    onViewportChanged: (circle: GeoArea, zoomLevel: number) => void;
    onSourceSelected: (source: DataSource | undefined) => void;
    onDistrictChanged: (district: DistrictType | null) => void;
};

const Map = ({
    viewLon,
    viewLat,
    siteReports,
    siteReportsWithHelidecks,
    aircrafts,
    marineVessels,
    lightning,
    webcams,
    areObstaclesOn,
    mapStyle,
    isTracking,
    isTrackingHeading,
    stopTracking,
    onViewportChanged,
    onSourceSelected,
    onDistrictChanged,
}: MapProps) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<mapboxgl.Map | null>(null);
    const [loadedMapStyle, setLoadedMapStyle] = useState<string | null>(null);
    const { zoom, showDemoController } = useSettings();
    const { coords }: { coords: any } = useParams();
    const history = useHistory();

    const {
        trackedLocationDemo,
        progress,
        tour,
        tourStatus,
        setProgress,
        setTourStatus,
    } = useTrackingDemo(map, isTracking, isTrackingHeading);

    const demoMode = tourStatus !== TourStatus.stopped;

    const { trackedLocation } = useTracking(
        map,
        isTracking,
        isTrackingHeading,
        demoMode
    );

    useObstaclesSource(areObstaclesOn, map, loadedMapStyle);
    usePowerlineSource(areObstaclesOn, map, loadedMapStyle);
    useAreaCircleSource(viewLon, viewLat, map, loadedMapStyle, isTracking);
    useControlZonesSource(map, loadedMapStyle);
    useRoadCameraSource(webcams, map, loadedMapStyle, onSourceSelected);
    useLightningSource(lightning, map, loadedMapStyle, onSourceSelected);
    useMarineSource(marineVessels, map, loadedMapStyle, onSourceSelected);
    useAircraftSource(aircrafts, map, loadedMapStyle, onSourceSelected);
    useHelideckSource(
        siteReportsWithHelidecks,
        map,
        loadedMapStyle,
        onSourceSelected
    );
    useUserLocationSourceDemo(
        map,
        loadedMapStyle,
        isTracking,
        trackedLocationDemo,
        demoMode
    );
    useUserLocationSource(
        map,
        loadedMapStyle,
        isTracking,
        trackedLocation,
        demoMode
    );
    useSiteReportSource(siteReports, map, loadedMapStyle, onSourceSelected);

    const { districtSearchCallbackRef } = useDistrict(map, webcams);

    useEffect(() => {
        if (map || mapContainer.current == null) {
            return;
        }

        const newMap = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle,
            center: [viewLon, viewLat],
            zoom: zoom.zoomLevel,
            doubleClickZoom: false,
            minZoom: zoomLevels[0],
            maxZoom: zoomLevels[zoomLevels.length - 1],
            attributionControl: false,
        });

        newMap.on("moveend", () => {
            handleBoundsChanged(newMap);
        });

        newMap.on("zoomend", () => {
            handleBoundsChanged(newMap);
        });

        newMap.on("dragstart", () => {
            stopTracking();
        });

        newMap.on("dblclick", (e: any) => {
            stopTracking();
            newMap.rotateTo(0);
            newMap.panTo(e.lngLat);
            history.push(
                `/${e.lngLat.lat},${e.lngLat.lng},${newMap.getZoom()}`
            );
        });

        newMap.on("click", (e: any) => {
            // check for airport
            const airports = newMap.queryRenderedFeatures(e.point, {
                layers: ["airports", "heliports"],
            });

            if (airports.length > 0) {
                const landingSite = airports[0] as any;

                onSourceSelected({
                    dataSourceType: DataSourceType.LandingSite,
                    id: landingSite.id,
                    type: landingSite.properties.type,
                    trafficType: landingSite.properties.trafficType,
                    iata: landingSite.properties.iata,
                    icao: landingSite.properties.icao,
                    name: landingSite.properties.name,
                    position: landingSite.geometry,
                });

                return;
            }

            // check for camera clusters
            const webcamClusters = newMap.queryRenderedFeatures(e.point, {
                layers: ["roadcams-cluster"],
            });

            if (webcamClusters.length > 0) {
                const lonLat = newMap.unproject([e.point.x, e.point.y]);
                const districtObjects = districtSearchCallbackRef.current(
                    lonLat.lng,
                    lonLat.lat
                );
                if (districtObjects.totalCount > 1) {
                    const clusterId = webcamClusters[0].properties?.cluster_id;
                    (
                        newMap.getSource("roadcams") as GeoJSONSource
                    ).getClusterExpansionZoom(
                        clusterId,
                        (err: any, zoom: any) => {
                            if (err) return;
                            onDistrictChanged({
                                worldCoords: [lonLat.lng, lonLat.lat],
                                screenCoords: [e.point.x, e.point.y],
                                zoomNeeded: zoom,
                                map: newMap,
                                ...districtObjects,
                            });
                        }
                    );
                    e.originalEvent.cancelBubble = true;
                }
                return;
            }
        });

        setMap(newMap);

        return () => newMap.remove();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (map == null) {
            return;
        }

        map.on("idle", () => {
            if (mapStyle !== loadedMapStyle) {
                setLoadedMapStyle(mapStyle);
            }
        });
    }, [map, mapStyle, loadedMapStyle]);

    useEffect(() => {
        if (map == null) {
            return;
        }

        map.zoomTo(zoom.zoomLevel, {
            duration: 500,
        });
    }, [map, mapStyle, zoom.zoomLevel]);

    useEffect(() => {
        if (map == null) {
            return;
        }

        map.setStyle(mapStyle);
    }, [map, mapStyle]);

    const handleBoundsChanged = (map: any) => {
        if (map == null) {
            return;
        }

        const southWest = map.getBounds().getSouthWest();
        const northEast = map.getBounds().getNorthEast();
        const center = map.getCenter();

        const distance = getDistance(
            center.lat,
            center.lng,
            southWest.lat,
            southWest.lng
        );

        zoom.setZoomLevel(map.getZoom());
        onViewportChanged(
            {
                lon: center.lng,
                lat: center.lat,
                radius: distance,
                xMin: southWest.lng,
                yMin: southWest.lat,
                xMax: northEast.lng,
                yMax: northEast.lat,
            },
            map.getZoom()
        );
    };

    useEffect(() => {
        if (map == null) {
            return;
        }
        map.resize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, window.innerHeight]);

    useEffect(() => {
        if (map == null) {
            return;
        }
        const centerWithZoom = parseCoordinatesWithZoom(coords);
        if (centerWithZoom === undefined) return;
        map.easeTo({
            center: [centerWithZoom[0], centerWithZoom[1]],
            zoom: centerWithZoom[2],
        });
    }, [map, coords]);

    return (
        <>
            <div
                ref={mapContainer}
                className="map-container"
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    zIndex: -1,
                }}
            >
                <Crosshair map={map} />
            </div>
            {showDemoController && (
                <DemoController
                    tour={tour}
                    progress={progress}
                    onProgressChange={setProgress}
                    onStatusChange={setTourStatus}
                />
            )}
            <Compass
                trackedLocation={trackedLocation}
                isTrackingHeading={isTrackingHeading}
                mapRotation={map?.getBearing() ?? 0}
            />
        </>
    );
};

export default React.memo(Map);
