export default function DetailsTabPanel({
    children,
    activeTab,
    index,
    ...other
}: any) {
    return (
        <div
            role="tabpanel"
            hidden={activeTab !== index}
            id={`details-tab-panel-${index}`}
            {...other}
        >
            {activeTab === index && children}
        </div>
    );
}
