import { Avatar, Card, CardContent, Grid, Snackbar } from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";
import { useApi } from "../contexts/ApiContext";

export default function ErrorNotification() {
    const { lastConnectionStatus } = useApi();

    return (
        <Snackbar
            open={lastConnectionStatus && lastConnectionStatus !== "Ok"}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            autoHideDuration={10000}
        >
            <Card>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar style={{ backgroundColor: "white" }}>
                                <ErrorIcon style={{ color: "red" }} />
                            </Avatar>
                        </Grid>
                        <Grid item>{lastConnectionStatus}</Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Snackbar>
    );
}
