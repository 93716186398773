import {
    Box,
    Button,
    ClickAwayListener,
    Drawer,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import { DataSourceType } from "../../hooks/useDataSources";
import { getCoordinatesDisplayText } from "../../utils/coordinates";
import LabelValue from "../core/LabelValue";
import NextIcon from "@material-ui/icons/NavigateNext";
import PrevIcon from "@material-ui/icons/NavigateBefore";
import { WebcamDto } from "../../api/ifis";

export type DistrictType = {
    screenCoords: number[];
    worldCoords: number[];
    roadCameras: WebcamDto[];
    zoomNeeded?: number | undefined;
    map?: any;
};

const DistrictPopup = ({
    district,
    onSourceSelected,
    onDistrictChanged,
}: {
    district: DistrictType | null;
    onSourceSelected: (source: any) => void;
    onDistrictChanged: (district: DistrictType | null) => void;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handlePopoverClose = () => {
        onDistrictChanged(null);
    };

    if (district == null) {
        return null;
    }

    let elements: JSX.Element[] = [];

    elements.push(
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{
                width: isMobile ? "80%" : "60%",
                marginLeft: "auto",
                marginRight: "auto",
                height: window.innerHeight * 0.3,
            }}
        >
            <LabelValue
                label="Position"
                value={getCoordinatesDisplayText(
                    district.worldCoords[1],
                    district.worldCoords[0]
                )}
            ></LabelValue>
            <LabelValue
                label="Nearby cameras: "
                value={district.roadCameras?.length ?? 0}
            ></LabelValue>
        </Box>
    );

    if (district.roadCameras) {
        district.roadCameras
            .map((x: any) => (
                <figure
                    style={{
                        position: "relative",
                        margin: "auto",
                        width: isMobile ? "80%" : "60%",
                    }}
                >
                    <img
                        style={{
                            maxHeight: window.innerHeight * 0.3 - 4,
                            height: window.innerHeight * 0.3 - 4,
                            width: "100%",
                            objectFit: "scale-down",
                        }}
                        key={x.id}
                        src={x.imageUrl}
                        alt="road camera"
                        onClick={() =>
                            onSourceSelected({
                                dataSourceType: DataSourceType.RoadCamera,
                                id: x.id,
                                description: x.description,
                                imageUrl: x.imageUrl,
                                timestamp: x.timestamp,
                                lon: x.lon,
                                lat: x.lat,
                            })
                        }
                    />
                    <figcaption
                        style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "black",
                        }}
                    >
                        {x.description}
                    </figcaption>
                </figure>
            ))
            .forEach((x: JSX.Element) => elements.push(x));
    }

    return (
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <Drawer
                anchor="bottom"
                open={district != null}
                hideBackdrop={true}
                variant="persistent"
                onClose={handlePopoverClose}
                onClick={(e) => {
                    if (e.isDefaultPrevented()) return;
                    handlePopoverClose();
                }}
            >
                <div
                    onClick={(e) => {
                        if (e.isDefaultPrevented()) return;
                        if (district.zoomNeeded) {
                            district.map.easeTo({
                                center: district.worldCoords,
                                zoom: district.zoomNeeded,
                            });
                        }
                        handlePopoverClose();
                    }}
                    style={{ height: window.innerHeight * 0.3 }}
                >
                    <Carousel
                        swipe={true}
                        animation="slide"
                        autoPlay={false}
                        navButtonsAlwaysVisible={true}
                        indicators={false}
                        fullHeightHover={true}
                        navButtonsWrapperProps={{
                            style: {
                                width: isMobile ? "10%" : "20%",
                                top: 0,
                            },
                        }}
                        NavButton={({
                            onClick,
                            className,
                            style,
                            next,
                            prev,
                        }: any) => {
                            return (
                                <Button
                                    onClick={onClick}
                                    style={{
                                        margin: 0,
                                        width: "100%",
                                        minWidth: "unset",
                                        height: "100%",
                                        color: "white",
                                        transition: "200ms",
                                        cursor: "pointer",
                                        backgroundColor: "#494949",
                                    }}
                                >
                                    {next && <NextIcon fontSize="large" />}
                                    {prev && <PrevIcon fontSize="large" />}
                                </Button>
                            );
                        }}
                    >
                        {elements}
                    </Carousel>
                </div>
            </Drawer>
        </ClickAwayListener>
    );
};

export default DistrictPopup;
