export enum DayOrNight {
    day = "Day",
    night = "Night",
}

export enum MediumOrHeavy {
    medium = "Medium",
    heavy = "Heavy",
}

export enum FocusValueNames {
    cb = "Cloud Base",
    dew = "Dew point",
    wind = "Wind",
    qnh = "QNH",
}

export const valueOrSlashes = (value: number | string | null | undefined) => {
    return value == null || value === "" ? "//" : value;
};
