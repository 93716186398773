import { ReactNode } from "react";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { valueOrSlashes } from "../../utils/constants";
import { useSettings } from "../../contexts/SettingsContext";
import { UnitKey } from "../../utils/units";

const useStyles = makeStyles(() =>
    createStyles({
        value: {
            paddingLeft: "0.5rem",
        },
        unit: {
            paddingLeft: "0.3rem",
        },
    })
);

type LabelValueProps = {
    label: string;
    value?: string | number | null | undefined;
    unitKey?: UnitKey;
    slashesWhenEmpty?: boolean;
    children?: ReactNode;
};

export default function LabelValue({
    label,
    value,
    unitKey,
    slashesWhenEmpty,
    children,
}: LabelValueProps) {
    const classes = useStyles();
    const { unitFor } = useSettings();

    return (
        <div>
            <Typography
                variant={children ? "overline" : "body2"}
                color="textSecondary"
                component="h2"
            >
                {label}
                <Typography
                    className={classes.value}
                    variant="h6"
                    component="span"
                    color="textPrimary"
                >
                    {slashesWhenEmpty ? valueOrSlashes(value) : value}
                </Typography>
                {value != null && unitKey != null && (
                    <Typography
                        className={classes.unit}
                        variant="caption"
                        component="span"
                        color="textSecondary"
                    >
                        {unitFor(unitKey)}
                    </Typography>
                )}
            </Typography>

            {children}
        </div>
    );
}
