import { useState } from "react";
import { FocusValueNames } from "../utils/constants";
import useLocalStorage from "./useLocalStorage";

export default function useFocusValue() {
    const [saveObject, getObject] = useLocalStorage("focusValue");
    const [focusValue, setFocusValueState] = useState(
        getObject() || FocusValueNames.dew
    );

    const setFocusValue = (focusValue: string) => {
        setFocusValueState(focusValue);
        saveObject(focusValue);
    };

    return { focusValue, setFocusValue };
}
