import { Card, CardContent, Grid } from "@material-ui/core";
import { HelideckReport, SiteReportDto } from "../../../api/ifis";
import LabelValue from "../../core/LabelValue";

export default function Weather({
    siteReport,
    helideck,
}: {
    siteReport: SiteReportDto;
    helideck: HelideckReport;
}) {
    const pressure = siteReport.pressure;
    const temperatureAndHumidity = siteReport.temperatureAndHumidity;
    const weather = siteReport.weather;

    const visibilityObservation =
        (siteReport.visibility?.observations.length ?? 0) > 0
            ? siteReport.visibility?.observations[0]
            : null;

    const cloudLayer1 =
        (siteReport.cloud?.cloudLayers.length ?? 0) > 0
            ? siteReport.cloud?.cloudLayers[0]
            : null;

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Air temp"
                            value={temperatureAndHumidity?.airTemperature}
                            slashesWhenEmpty
                            unitKey={"temp"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Dew point"
                            value={temperatureAndHumidity?.dewPoint}
                            slashesWhenEmpty
                            unitKey={"temp"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="QNH"
                            value={pressure?.qnh}
                            slashesWhenEmpty
                            unitKey={"pressure"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Wave height"
                            value={null /*wave height*/}
                            slashesWhenEmpty
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Conditions"
                            value={weather?.metarPresentWeather}
                            slashesWhenEmpty
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Visibility (RVR)"
                            value={visibilityObservation?.averageVisibility}
                            slashesWhenEmpty
                            unitKey={"visibility"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Cloud Height 1"
                            value={cloudLayer1?.height}
                            slashesWhenEmpty
                            unitKey={"cloudHeight"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Cloud Cover 1"
                            value={cloudLayer1?.cover}
                            slashesWhenEmpty
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Vessel Heading"
                            value={helideck?.helideckMovement.vesselHeading?.toFixed(
                                0
                            )}
                            slashesWhenEmpty
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue
                            label="Helideck Heading"
                            value={helideck.helideckMovement.helideckHeading?.toFixed(
                                0
                            )}
                            slashesWhenEmpty
                        ></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
