import { useState } from "react";
import { getDistance } from "../utils/geoCalculations";
import useLocalStorage from "./useLocalStorage";

export type GeoArea = {
    lon: number;
    lat: number;
    radius: number;
    xMin: number;
    yMin: number;
    xMax: number;
    yMax: number;
};

export default function useViewArea() {
    const [saveObject, getObject] = useLocalStorage("view-area");
    const [viewArea, setViewArea] = useState<GeoArea>({
        lon: getObject()?.lon || 11,
        lat: getObject()?.lat || 61,
        radius: 40,
        xMin: 10,
        xMax: 12,
        yMin: 60,
        yMax: 62,
    });
    const [viewZoom, setViewZoom] = useState<number>(10);
    const [loadedArea, setLoadedArea] = useState<GeoArea>({
        lon: getObject()?.lon || 11,
        lat: getObject()?.lat || 61,
        radius: 40,
        xMin: 10,
        xMax: 12,
        yMin: 60,
        yMax: 62,
    });
    const [loadedZoom, setLoadedZoom] = useState<number>(0);

    const updateLoadedArea = (
        circle: GeoArea,
        viewCircle: GeoArea,
        zoomLevel: number
    ) => {
        setLoadedArea(circle);
        setLoadedZoom(zoomLevel);
        updateViewArea(viewCircle, zoomLevel);
    };

    const updateViewArea = (viewArea: GeoArea, zoomLevel: number) => {
        setViewArea(viewArea);
        saveObject(viewArea);
        setViewZoom(zoomLevel);
    };

    const isViewOutsideLoadedArea = () => {
        const distance = getDistance(
            viewArea.lat,
            viewArea.lon,
            loadedArea.lat,
            loadedArea.lon
        );
        return distance > loadedArea.radius - viewArea.radius;
    };

    const isZoomNextLevel = () => {
        return Math.floor(viewZoom) - Math.floor(loadedZoom) >= 1;
    };

    const shouldLoad = isViewOutsideLoadedArea() || isZoomNextLevel();

    return {
        viewArea,
        updateViewArea,
        updateLoadedArea,
        shouldLoad,
    };
}
