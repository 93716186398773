import { Map } from "mapbox-gl";
import { addClusterLayer, ClusterOptions } from "./addClusterLayer";

export function addGeoJsonSource(
    map: Map,
    sourceName: string,
    clusterOptions?: ClusterOptions
) {
    if (map == null || map.getSource(sourceName)) {
        return;
    }

    map.addSource(sourceName, {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: [],
        },
        cluster: clusterOptions != null,
        clusterMaxZoom: clusterOptions?.clusterMaxZoom || 13,
        clusterRadius: clusterOptions?.clusterRadius || 50,
    });

    if (clusterOptions != null) {
        addClusterLayer(map, sourceName, clusterOptions);
    }
}
