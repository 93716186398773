import { useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import StopIcon from "@material-ui/icons/Stop";
import { Slider } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: 1,
            position: "relative",
            top: 0,
        },
        content: {
            display: "flex",
            justifyContent: "center",
        },
        controls: {
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
        },
        playIcon: {
            height: 38,
            width: 38,
        },
        slider: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        },
    })
);

export enum TourStatus {
    stopped,
    playing,
    paused,
}

const TourStatusIcon = ({
    tourStatus,
    className,
}: {
    tourStatus: TourStatus;
    className: string;
}) => {
    return tourStatus === TourStatus.playing ? (
        <PauseIcon className={className} />
    ) : (
        <PlayArrowIcon className={className} />
    );
};

export default function DemoController({
    tour,
    progress,
    onStatusChange,
    onProgressChange,
}: any) {
    const classes = useStyles();
    const [tourStatus, setTourStatus] = useState<TourStatus>(
        TourStatus.stopped
    );

    const handlePlayPause = () => {
        const newStatus =
            tourStatus === TourStatus.playing
                ? TourStatus.paused
                : TourStatus.playing;

        setTourStatus(newStatus);
        onStatusChange(newStatus);
    };

    const handleStop = () => {
        setTourStatus(TourStatus.stopped);
        onStatusChange(TourStatus.stopped);
    };

    const handleProgressSlider = (event: object, value: number | number[]) => {
        onProgressChange(value);
    };

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <div>
                    <Typography component="h5" variant="h5">
                        Tour
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {`${tour.StartName} to ${tour.StopName}`}
                    </Typography>
                </div>
                <div className={classes.controls}>
                    <IconButton onClick={handlePlayPause}>
                        <TourStatusIcon
                            className={classes.playIcon}
                            tourStatus={tourStatus}
                        />
                    </IconButton>
                    <IconButton aria-label="stop" onClick={handleStop}>
                        <StopIcon className={classes.playIcon} />
                    </IconButton>
                </div>
            </CardContent>

            <div className={classes.slider}>
                <Slider
                    value={progress}
                    valueLabelDisplay="auto"
                    step={10}
                    marks
                    min={tour.StartIndex}
                    max={tour.StopIndex}
                    onChange={handleProgressSlider}
                    valueLabelFormat={(x) =>
                        `${Math.round((x / tour.StopIndex) * 100)}%`
                    }
                />
            </div>
        </Card>
    );
}
