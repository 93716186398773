import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

const clickPlugin = new ClickAnalyticsPlugin();
const clickPluginConfig = {
    autoCapture: true,
    dropInvalidEvents: true,
    dataTags: {
        useDefaultContentNameOrId: true,
        customDataPrefix: "data-sample-",
    },
};

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "8c7f2440-5005-47d6-a2a3-93293e9f585a",
        extensions: [reactPlugin, clickPlugin],
        extensionConfig: {
            [clickPlugin.identifier]: clickPluginConfig,
        },
    },
});

appInsights.loadAppInsights();

export default reactPlugin;
