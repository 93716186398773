const mapStyles = [
    {
        name: "Default",
        url: "mapstyles/default.json",
        maxZoom: 14,
    },
    {
        name: "Light",
        url: "mapstyles/light.json",
        maxZoom: 14,
    },
    {
        name: "Dark",
        url: "mapstyles/dark.json",
        maxZoom: 14,
    },
];

export default mapStyles;
