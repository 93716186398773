import { useEffect, useState } from "react";
import CompassImg from "../icons-v2/Compass40.png";

export default function Compass({
    trackedLocation,
}: {
    trackedLocation: GeolocationCoordinates | null;
    isTrackingHeading: boolean;
    mapRotation: number;
}) {
    const [orientation, setOrientation] = useState<number>(0);

    useEffect(() => {
        const handleOrientation = (event: DeviceOrientationEvent) => {
            setOrientation(
                (trackedLocation?.heading ?? 0) - (event.alpha ?? 0)
            );
        };
        if (window.DeviceOrientationEvent) {
            window.addEventListener(
                "deviceorientation",
                handleOrientation,
                true
            );
        }

        return () => {
            window.removeEventListener(
                "deviceorientation",
                handleOrientation,
                true
            );
        };
    }, [trackedLocation?.heading]);

    return (
        <div>
            <img
                src={CompassImg}
                alt="compass"
                style={{
                    position: "absolute",
                    top: 20,
                    left: "auto",
                    right: 8,
                    transform: `rotate(${-orientation}deg)`,
                }}
            />
        </div>
    );
}
