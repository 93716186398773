import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useState,
} from "react";
import useOperation, { OperationHookType } from "../hooks/useOperation";
import useFavorites, { FavoriteHookType } from "../hooks/useFavorites";
import useMapStyle, { MapStyleHookType } from "../hooks/useMapStyle";
import useStorageRegions, {
    StorageRegionsHookType,
} from "../hooks/useStorageRegions";
import useZoom from "../hooks/useZoom";
import useFocusValue from "../hooks/useFocusValue";
import { unitForRegion, UnitKey } from "../utils/units";
import useLocalStorage from "../hooks/useLocalStorage";
import { distanceUnits } from "../components/map/Crosshair";

type SettingsContextType = {
    favoritesService: FavoriteHookType;
    mapStyleService: MapStyleHookType;
    operationService: OperationHookType;
    storageRegionsService: StorageRegionsHookType;
    zoom: {
        zoomLevel: number;
        setZoomLevel: (level: number) => void;
        zoomPresets: number[];
        currZoomPreset: number;
    };
    showHeightBar: boolean;
    focus: {
        focusValue: string;
        setFocusValue: (focusValue: string) => void;
    };
    distanceUnit: distanceUnits;
    showDemoController: boolean;
    toggleDemoController: () => void;
    toggleHeightBar: () => void;
    unitFor: (unitKey: UnitKey) => string;
    toggleDistanceUnit: () => void;
    setZoomPreset: (preset: number, value: number) => void;
};

const settingsContext = createContext<SettingsContextType>(
    {} as SettingsContextType
);

export const SettingsProvider = ({ children }: { children?: ReactNode }) => {
    const [saveObject, getObject] = useLocalStorage("height-bar");
    const [saveDistanceUnit, getDistanceUnit] =
        useLocalStorage("distance-unit");
    const [saveZoomPresets, getZoomPresets] = useLocalStorage("zoom-presets");
    const [showHeightBar, setShowHeightBar] = useState(
        Boolean(getObject() ?? true)
    );
    const [distanceUnit, setDistanceUnit] = useState(
        (getDistanceUnit() as distanceUnits) ?? "m"
    );
    const [showDemoController, setShowDemoController] = useState(false);
    const [zoomPresets, setZoomPresets] = useState(
        (getZoomPresets() as number[]) ?? [6, 10, 14]
    );
    const favoritesService = useFavorites();
    const mapStyleService = useMapStyle();
    const operationService = useOperation();
    const storageRegionsService = useStorageRegions();
    const zoom = useZoom(zoomPresets);
    const focus = useFocusValue();
    const unitFor = useCallback(
        (unitKey: UnitKey) => unitForRegion("eu", unitKey),
        []
    );

    const toggleHeightBar = () => {
        setShowHeightBar(!showHeightBar);
        saveObject(!showHeightBar);
    };

    const toggleDistanceUnit = () => {
        const newDistanceUnit = distanceUnit === "m" ? "nmi" : "m";
        setDistanceUnit(newDistanceUnit);
        saveDistanceUnit(newDistanceUnit);
    };

    const toggleDemoController = () => {
        setShowDemoController(!showDemoController);
    };

    const setZoomPreset = (preset: number, value: number) => {
        if (preset < 0 && preset >= zoomPresets.length) return;
        if (value < 6 || value > 14) return;
        zoomPresets[preset] = value;
        setZoomPresets(zoomPresets);
        saveZoomPresets(zoomPresets);
    };

    return (
        <settingsContext.Provider
            value={{
                favoritesService,
                mapStyleService,
                operationService: operationService,
                storageRegionsService,
                zoom,
                showHeightBar,
                focus,
                distanceUnit,
                showDemoController,
                toggleDemoController,
                unitFor,
                toggleHeightBar,
                toggleDistanceUnit,
                setZoomPreset,
            }}
        >
            {children}
        </settingsContext.Provider>
    );
};

export const useSettings = () => useContext(settingsContext);
