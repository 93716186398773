import { useSettings } from "../../../contexts/SettingsContext";
import { getLimits, getMaxMovement } from "./helideckUtils";
import { Card, CardContent, Grid } from "@material-ui/core";
import LabelValue from "../../core/LabelValue";
import { HelideckReport } from "../../../api/ifis";

export default function Helideck({ helideck }: { helideck: HelideckReport }) {
    const { operationService } = useSettings();

    const {
        maxRoll,
        maxPitch,
        maxInclination,
        maxAvgHeaveRate,
    } = getMaxMovement(helideck.helideckMovement, operationService.isNight());

    const { rollAndPitch, inclination, avgHeaveRate } = getLimits(
        helideck.helideckDetails,
        operationService.isNight(),
        operationService.isHeavy()
    );

    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <LabelValue label="Motion, 20 minutes">
                            <LabelValue
                                label="Max Roll"
                                value={maxRoll}
                                slashesWhenEmpty
                            />
                            <LabelValue
                                label="Max Pitch"
                                value={maxPitch}
                                slashesWhenEmpty
                            />
                            <LabelValue
                                label="Max Inclination"
                                value={maxInclination}
                                slashesWhenEmpty
                            />
                            <LabelValue
                                label="Max Avg HR"
                                value={maxAvgHeaveRate}
                                slashesWhenEmpty
                            />
                        </LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue label="Limits">
                            <LabelValue
                                label="Limit Roll"
                                value={rollAndPitch}
                                slashesWhenEmpty
                            />
                            <LabelValue
                                label="Limit Pitch"
                                value={rollAndPitch}
                                slashesWhenEmpty
                            />
                            <LabelValue
                                label="Max Avg HR"
                                value={inclination}
                                slashesWhenEmpty
                            />
                            <LabelValue
                                label="Max Avg HR"
                                value={avgHeaveRate}
                                slashesWhenEmpty
                            />
                        </LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
