import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import AircraftDetails from "./AircraftDetails";
import LightningDetails from "./LightningDetails";
import MarineVesselDetails from "./MarineVesselDetails";
import SiteReportDetails from "./SiteReportDetails";
import WebcamDetails from "./WebcamDetails";
import { Fab, SvgIcon, Zoom } from "@material-ui/core";
import { DataSource } from "../../api/ifis";
import { DataSourceType } from "../../hooks/useDataSources";
import LandingSiteDetails from "./LandingSiteDetails";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        fab: {
            position: "fixed",
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    })
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type AreaDetailsProps = {
    open: boolean;
    sourceDetails: DataSource | undefined;
    onClose: () => void;
};

export default function AreaDetailsDialog({
    open,
    sourceDetails,
    onClose,
}: AreaDetailsProps) {
    const classes = useStyles();

    return (
        <div>
            <Dialog fullScreen open={open} TransitionComponent={Transition}>
                <SourceDetails sourceDetails={sourceDetails} />
                <Zoom in={open} unmountOnExit>
                    <Fab
                        aria-label="close"
                        className={classes.fab}
                        onClick={onClose}
                    >
                        <SvgIcon>
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </SvgIcon>
                    </Fab>
                </Zoom>
            </Dialog>
        </div>
    );
}

type SourceDetailsProps = { sourceDetails: DataSource | null | undefined };

const SourceDetails = ({ sourceDetails }: SourceDetailsProps) => {
    switch (sourceDetails?.dataSourceType) {
        case DataSourceType.RoadCamera:
            return <WebcamDetails sourceDetails={sourceDetails} />;
        case DataSourceType.AirTraffic:
            return <AircraftDetails sourceDetails={sourceDetails} />;
        case DataSourceType.Lightning:
            return <LightningDetails sourceDetails={sourceDetails} />;
        case DataSourceType.MarineTraffic:
            return <MarineVesselDetails sourceDetails={sourceDetails} />;
        case DataSourceType.Weather:
            return <SiteReportDetails sourceDetails={sourceDetails} />;
        case DataSourceType.LandingSite:
            return <LandingSiteDetails sourceDetails={sourceDetails} />;
        default:
            return null;
    }
};
