import { Fab, Grid } from "@material-ui/core";
import NeutralSwitch from "./NeutralSwitch";

export default function IconSwitch({
    leftIcon,
    rightIcon,
    isRight,
    toggle,
    ...others
}: any) {
    return (
        <Fab variant="extended" disableRipple {...others}>
            <Grid component="label" container alignItems="center" spacing={0}>
                <Grid item>
                    <img
                        src={leftIcon}
                        alt="left"
                        style={{
                            verticalAlign: "bottom",
                        }}
                    />
                </Grid>
                <Grid item>
                    <NeutralSwitch checked={isRight()} onChange={toggle} />
                </Grid>
                <Grid item>
                    <img
                        src={rightIcon}
                        alt="right"
                        style={{
                            verticalAlign: "bottom",
                        }}
                    />
                </Grid>
            </Grid>
        </Fab>
    );
}
