import { CameraOptions, Map } from "mapbox-gl";
import { useEffect, useState } from "react";

export default function useTracking(
    map: Map | null,
    isTracking: boolean,
    isTrackingHeading: boolean,
    demoMode: boolean
) {
    const [
        trackedLocation,
        setTrackedLocation,
    ] = useState<GeolocationCoordinates | null>(null);

    useEffect(() => {
        if (map == null || demoMode) {
            return;
        }

        const handleWatchPosition = (position: GeolocationPosition) => {
            setTrackedLocation(position?.coords);

            if (position == null || position.coords == null) {
                return;
            }

            if (isTracking) {
                let jumpOptions: CameraOptions = {
                    center: {
                        lng: position.coords.longitude,
                        lat: position.coords.latitude,
                    },
                };
                if (!isTrackingHeading) {
                    jumpOptions = { bearing: 0, ...jumpOptions };
                }
                map?.jumpTo(jumpOptions);
            }
        };

        const handleOrientation = (event: DeviceOrientationEvent) => {
            const rotateDegrees = event.alpha;

            if (isTrackingHeading) {
                map?.rotateTo(rotateDegrees ?? 0);
            }
        };

        const newWatchId = navigator.geolocation.watchPosition(
            handleWatchPosition,
            (positionError: GeolocationPositionError) =>
                console.log("positionError"),
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            }
        );

        if (window.DeviceOrientationEvent) {
            window.addEventListener(
                "deviceorientation",
                handleOrientation,
                true
            );
        }

        return () => {
            navigator.geolocation.clearWatch(newWatchId);
            window.removeEventListener(
                "deviceorientation",
                handleOrientation,
                true
            );
        };
    }, [demoMode, isTracking, isTrackingHeading, map]);

    return { trackedLocation };
}
