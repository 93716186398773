import { Expression, GeoJSONSource, Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { Feature, GeoJsonProperties, Geometry } from "geojson";
import { addSymbolLayer } from "../addSymbolLayer";
import { addGeoJsonSource } from "../addGeoJsonSource";
import { useSettings } from "../../../../contexts/SettingsContext";
import { FocusValueNames } from "../../../../utils/constants";
import ArrowIcon from "../../../../icons/iconmonstr-arrow-20-24.png";
import CircleIcon from "../../../../icons/circle.png";
import { SiteReportCompactDto } from "../../../../api/ifis";

export default function useSiteReportSource(
    siteReports: SiteReportCompactDto[],
    map: Map | null,
    loadedMapStyle: string | null,
    onSourceSelected: (source: SiteReportCompactDto | undefined) => void
) {
    const { focus } = useSettings();
    const siteReportsRef = useRef<SiteReportCompactDto[]>();

    useEffect(() => {
        siteReportsRef.current = siteReports;
    }, [siteReports]);
    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const sourceName = "site-reports";
        if (map.getSource(sourceName)) {
            return;
        }

        const onClick = (e: any) => {
            if (e.features == null) {
                return;
            }

            // cameras are shown under weather but should take click priority over them
            if (
                map.queryRenderedFeatures(e.point, {
                    layers: ["roadcams-symbol-layer"],
                }).length > 0
            )
                return;

            onSourceSelected(
                siteReportsRef.current?.find(
                    (x) => x.name === e.features[0].properties?.id
                )
            );
        };

        addGeoJsonSource(map, sourceName);

        map.loadImage(ArrowIcon, (_error, result) =>
            map.addImage("wind-arrow", result!)
        );

        map.loadImage(CircleIcon, (_error, result) =>
            map.addImage("nostarcircle", result!)
        );

        const stars = [
            "case",
            ["==", ["get", "priority"], 1],
            "\n***",
            ["==", ["get", "priority"], 2],
            "\n**",
            "",
        ];

        const textSize = [
            "case",
            ["==", ["get", "priority"], 1],
            16,
            ["==", ["get", "priority"], 2],
            14,
            12,
        ] as Expression;

        const haloColor = [
            "case",
            ["==", ["get", "priority"], 1],
            "#9C00BF",
            "#424242",
        ] as Expression;

        addSymbolLayer(map, {
            sourceName: sourceName,
            layerPrefix: "-wind",
            layout: {
                "icon-rotate": ["get", "windDir"],
                "icon-image": [
                    "case",
                    ["!=", ["get", "windDir"], null],
                    "wind-arrow",
                    "nostarcircle",
                ],
                "text-field": [
                    "format",
                    [
                        "case",
                        ["!=", ["get", "windSpeed"], null],
                        ["concat", ["get", "windSpeed"], "KT", stars],
                        "//",
                    ],
                ],
                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                "text-size": textSize,
                "text-letter-spacing": 0.05,
                "text-anchor": "left",
                "text-offset": [1, 0],
                "icon-offset": [-1, 0],
                "icon-size": 0.8,
                "symbol-sort-key": ["get", "priority"],
            },
            paint: {
                "text-color": "#fff",
                "text-halo-color": haloColor,
                "text-halo-width": 8,
            },
            filter: [
                "any",
                ["!=", ["get", "windSpeed"], null],
                ["!=", ["get", "windDir"], null],
            ],
            onClick,
        });

        addSymbolLayer(map, {
            sourceName: sourceName,
            layerPrefix: "-cb",
            layout: {
                "icon-image": "nostarcircle",
                "text-field": [
                    "concat",
                    [
                        "case",
                        ["==", ["get", "cloudBase"], "HIGH"],
                        ["format", "HIGH", { "font-scale": 0.7 }],
                        ["concat", ["get", "cloudBase"], "FT"],
                    ],
                    stars,
                ],
                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                "text-size": textSize,
                "text-letter-spacing": 0.05,
                "text-anchor": "left",
                "text-offset": [1, 0],
                "symbol-sort-key": ["get", "priority"],
            },
            paint: {
                "text-color": "#fff",
                "text-halo-color": haloColor,
                "text-halo-width": 8,
            },
            filter: ["!=", ["get", "cloudBase"], null],

            onClick,
        });

        addSymbolLayer(map, {
            sourceName: sourceName,
            layerPrefix: "-dew",
            layout: {
                "icon-image": "nostarcircle",
                "text-field": [
                    "format",
                    [
                        "concat",
                        ["get", "temp"],
                        "/",
                        ["get", "dew"],
                        "C",
                        stars,
                    ],
                ],
                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                "text-size": textSize,
                "text-letter-spacing": 0.05,
                "text-anchor": "left",
                "text-offset": [1, 0],
                "symbol-sort-key": ["get", "priority"],
            },
            paint: {
                "text-color": "#fff",
                "text-halo-color": haloColor,
                "text-halo-width": 8,
            },
            filter: [
                "all",
                ["!=", ["get", "dew"], null],
                ["!=", ["get", "temp"], null],
            ],
            onClick,
        });

        addSymbolLayer(map, {
            sourceName: sourceName,
            layerPrefix: "-qnh",
            layout: {
                "icon-image": "nostarcircle",
                "text-field": [
                    "format",
                    ["concat", ["get", "qnh"], "HPA", stars],
                ],
                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                "text-size": textSize,
                "text-letter-spacing": 0.05,
                "text-anchor": "left",
                "text-offset": [1, 0],
                "symbol-sort-key": ["get", "priority"],
            },
            paint: {
                "text-color": "#fff",
                "text-halo-color": haloColor,
                "text-halo-width": 8,
            },
            filter: ["!=", ["get", "qnh"], null],
            onClick,
        });
    }, [map, loadedMapStyle, siteReports, focus.focusValue, onSourceSelected]);

    useEffect(() => {
        if (map == null) {
            return;
        }

        const source = map.getSource("site-reports") as GeoJSONSource;

        if (source == null) {
            return;
        }

        const siteReportFeatures = siteReports.map((x: any) => {
            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [x.lon, x.lat],
                },
                properties: {
                    id: x.name,
                    temp: x.airTemperature,
                    dew: x.dewPoint,
                    windDir:
                        x.windDirection10Min != null
                            ? x.windDirection10Min + 90
                            : null,
                    windSpeed: x.windSpeed10Min,
                    cloudBase: getCloudBaseDisplayText({
                        temp: x.airTemperature,
                        dew: x.dewPoint,
                        cloudBase: x.cloudBase,
                    }),
                    qnh: x.qnh,
                    priority:
                        x.source === "METAR" || x.source === "Omnia"
                            ? 1
                            : x.source === "Met Norway"
                            ? 2
                            : 3,
                },
            } as Feature<Geometry, GeoJsonProperties>;
        });

        source.setData({
            type: "FeatureCollection",
            features: siteReportFeatures,
        });

        if (focus.focusValue === FocusValueNames.cb) {
            map.setLayoutProperty(
                "site-reports-cb-symbol-layer",
                "visibility",
                "visible"
            );
            map.setLayoutProperty(
                "site-reports-dew-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-wind-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-qnh-symbol-layer",
                "visibility",
                "none"
            );
        } else if (focus.focusValue === FocusValueNames.dew) {
            map.setLayoutProperty(
                "site-reports-cb-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-dew-symbol-layer",
                "visibility",
                "visible"
            );
            map.setLayoutProperty(
                "site-reports-wind-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-qnh-symbol-layer",
                "visibility",
                "none"
            );
        } else if (focus.focusValue === FocusValueNames.wind) {
            map.setLayoutProperty(
                "site-reports-cb-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-dew-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-wind-symbol-layer",
                "visibility",
                "visible"
            );
            map.setLayoutProperty(
                "site-reports-qnh-symbol-layer",
                "visibility",
                "none"
            );
        } else {
            map.setLayoutProperty(
                "site-reports-cb-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-dew-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-wind-symbol-layer",
                "visibility",
                "none"
            );
            map.setLayoutProperty(
                "site-reports-qnh-symbol-layer",
                "visibility",
                "visible"
            );
        }
    }, [map, siteReports, focus.focusValue, loadedMapStyle]);
}

type CloudBaseInfo = {
    temp: number;
    dew: number;
    cloudBase: number | undefined;
};

const getCloudBaseDisplayText = ({ temp, dew, cloudBase }: CloudBaseInfo) => {
    if (cloudBase == null) {
        return null;
    }

    // probably no clouds
    if (temp - dew > 5) {
        return "HIGH";
    }

    return cloudBase * 100;
};
