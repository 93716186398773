type Unit = {
    windSpeed: string;
    windDirection: string;
    temp: string;
    pressure: string;
    visibility: string;
    cloudHeight: string;
    humidity: string;
};

type UnitRegion = "eu";

type Units = {
    [region in UnitRegion]: Unit;
};

export type UnitKey = keyof Unit;

const unitsEu: Unit = {
    windSpeed: "kt",
    windDirection: "°",
    temp: "℃",
    pressure: "hPa",
    visibility: "m",
    cloudHeight: "ft",
    humidity: "%",
};

const units: Units = {
    eu: unitsEu,
};

export const unitForRegion = (region: UnitRegion, key: UnitKey) => {
    return units[region][key];
};
