import LabelValue from "../core/LabelValue";
import Grid from "@material-ui/core/Grid";
import { getCoordinatesDisplayText } from "../../utils/coordinates";
import { getTimestampDisplayText } from "../../utils/time";
import CameraIcon from "../../icons-v2/CameraLight.png";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";
import { WebcamDto } from "../../api/ifis";
import { useState } from "react";

export default function WebcamDetails({
    sourceDetails,
}: {
    sourceDetails: WebcamDto;
}) {
    const [fit, setFit] = useState(false);

    const handleImgClick = () => {
        setFit(!fit);
    };

    return (
        <div>
            <Card variant="outlined">
                <CardHeader
                    avatar={<img src={CameraIcon} alt="camera" />}
                    title="Camera details"
                ></CardHeader>
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <LabelValue
                                label="Location"
                                value={sourceDetails.description}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12}>
                            <LabelValue
                                label="Coordinates"
                                value={getCoordinatesDisplayText(
                                    sourceDetails.lat,
                                    sourceDetails.lon
                                )}
                            ></LabelValue>
                        </Grid>
                        {sourceDetails.timestamp && (
                            <Grid item xs={12}>
                                <LabelValue
                                    label="Timestamp"
                                    value={getTimestampDisplayText(
                                        sourceDetails.timestamp
                                    )}
                                ></LabelValue>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
                <Box padding={2}>
                    <img
                        src={sourceDetails.imageUrl}
                        alt="Webcam"
                        onClick={handleImgClick}
                        style={{
                            width: fit ? "100%" : "unset",
                            // objectFit: "scale-down",
                        }}
                    ></img>
                </Box>
            </Card>
        </div>
    );
}
