export const getCoordinatesDisplayText = (lat: number, lon: number) => {
    return `${lat.toFixed(5)}, ${lon.toFixed(5)}`;
};

export const parseCoordinatesWithZoom = (
    coordsWithZoom: string
): number[] | undefined => {
    if (!coordsWithZoom) return undefined;
    // lat,lon,zoom
    const components = coordsWithZoom.split(",");
    if (components.length !== 3) return undefined;
    for (let i = 0; i < 3; i++) {
        if (isNaN(parseFloat(components[i]))) return undefined;
    }
    return [+components[1], +components[0], +components[2]];
};
