import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { getCoordinatesDisplayText } from "../../utils/coordinates";
import WeatherIcon from "../../icons-v2/WeatherStationLight.png";
import { CardHeader, Grid } from "@material-ui/core";
import LabelValue from "../core/LabelValue";

export default function SiteDetailsCard({
    lat,
    lon,
    elevation,
    source,
}: {
    lat: number;
    lon: number;
    elevation: number;
    source: string;
}) {
    return (
        <Card variant="outlined">
            <CardHeader
                title="Weather station"
                avatar={<img src={WeatherIcon} alt="station" />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Coordinates"
                            value={getCoordinatesDisplayText(lat, lon)}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Elevation"
                            value={elevation}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue label="Source" value={source}></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
