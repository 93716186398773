import { Map } from "mapbox-gl";
import { useEffect } from "react";

export default function usePowerlineSource(
    areObstaclesOn: boolean,
    map: Map | null,
    loadedMapStyle: string | null
) {
    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        map.setLayoutProperty(
            "powerlines",
            "visibility",
            areObstaclesOn ? "visible" : "none"
        );

        map.setLayoutProperty(
            "powerlines-symbols",
            "visibility",
            areObstaclesOn ? "visible" : "none"
        );
    }, [map, loadedMapStyle, areObstaclesOn]);
}
