export type StorageRegion = {
    name: string;
    latlngs: any;
};

const regions: StorageRegion[] = [
    {
        name: "South Norway",
        latlngs: [
            [57.9, 4.5],
            [63.5, 12.7],
        ],
    },
    {
        name: "Central Norway",
        latlngs: [
            [63.4, 8.2],
            [69.5, 19.1],
        ],
    },
    {
        name: "North Norway",
        latlngs: [
            [67.2, 14.2],
            [71.2, 31.5],
        ],
    },
];

export default regions;
