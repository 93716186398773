import { Avatar, Card, CardContent, Grid, Snackbar } from "@material-ui/core";
import { useApi } from "../contexts/ApiContext";
import { ReactComponent as GoodSignalIcon } from "../icons/iconmonstr-wireless-1.svg";
import { ReactComponent as MediumSignalIcon } from "../icons/iconmonstr-wireless-2.svg";
import { ReactComponent as LowSignalIcon } from "../icons/iconmonstr-wireless-3.svg";
import { ReactComponent as NoSignalIcon } from "../icons/iconmonstr-wireless-4.svg";

export default function TransferQuality() {
    const { averageDuration, lastConnectionStatus } = useApi();

    return (
        <Snackbar
            open={
                !(lastConnectionStatus && lastConnectionStatus !== "Ok") &&
                averageDuration != null &&
                averageDuration > 30000
            }
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            autoHideDuration={10000}
        >
            <Card>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Avatar style={{ backgroundColor: "white" }}>
                                <TransferQualityIcon
                                    averageDuration={averageDuration}
                                />
                            </Avatar>
                        </Grid>
                        <Grid item>High latency!</Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Snackbar>
    );
}

function TransferQualityIcon({ averageDuration }: any) {
    if (averageDuration == null) {
        return <NoSignalIcon fill="red" />;
    }

    if (averageDuration <= 1000) {
        return <GoodSignalIcon fill="green" />;
    }

    if (averageDuration > 1000 && averageDuration <= 2000) {
        return <MediumSignalIcon fill="yellow" />;
    }

    return <LowSignalIcon fill="red" />;
}
