import { useState, useEffect, useCallback } from "react";
import { SiteReportCompactDto, SiteReportDto } from "../api/ifis";
import { useApi } from "../contexts/ApiContext";

export default function useSiteReportDetails(
    sourceDetails: SiteReportCompactDto
) {
    const [siteReport, setSiteReport] = useState<SiteReportDto>();
    const { getJson } = useApi();

    const getSiteReportByName = useCallback(
        async (name: string) => {
            return await getJson(`/meteorology/site-report/${name}`);
        },
        [getJson]
    );

    useEffect(() => {
        if (sourceDetails == null) {
            return;
        }

        const uriFriendlyName = encodeURIComponent(sourceDetails.name);

        getSiteReportByName(uriFriendlyName).then((siteReport) => {
            setSiteReport(siteReport);
        });
    }, [getSiteReportByName, sourceDetails]);

    return siteReport;
}
