import Grid from "@material-ui/core/Grid";
import LabelValue from "../core/LabelValue";
import { getCoordinatesDisplayText } from "../../utils/coordinates";
import { getTimestampDisplayText } from "../../utils/time";
import LightningIcon from "../../icons-v2/LightningLight.png";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { LightningDto } from "../../api/ifis";

export default function LightningDetails({
    sourceDetails,
}: {
    sourceDetails: LightningDto;
}) {
    return (
        <Card variant="outlined">
            <CardHeader
                avatar={LightningIcon}
                title="Lightning details"
            ></CardHeader>
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Timestamp"
                            value={getTimestampDisplayText(
                                sourceDetails.timestamp
                            )}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Coordinates"
                            value={getCoordinatesDisplayText(
                                sourceDetails.lat,
                                sourceDetails.lon
                            )}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Peak Current"
                            value={sourceDetails.peakCurrentEstimate}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Multiplicity"
                            value={sourceDetails.multiplicity}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Number of Sensors"
                            value={sourceDetails.solutionNoSensors}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Ellipse Angle"
                            value={sourceDetails.ellipseAngle}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Ellipse Semi-Major Axis"
                            value={sourceDetails.ellipseSemiMajorAxis}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Ellipse Semi-Minor Axis"
                            value={sourceDetails.ellipseSemiMinorAxis}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Chi Square"
                            value={sourceDetails.chiSquare}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Rise Time"
                            value={sourceDetails.riseTime}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Peak to Zero"
                            value={sourceDetails.peakToZeroTime}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Max Rate of Rise"
                            value={sourceDetails.maxRateOfRise}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Cloud Indicator"
                            value={sourceDetails.cloudIndicator}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Angle Indicator"
                            value={sourceDetails.angleIndicator}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Signal Indicator"
                            value={sourceDetails.signalIndicator}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="TimingIndicator"
                            value={sourceDetails.timingIndicator}
                        ></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
