import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { valueOrSlashes } from "../../utils/constants";
import WindIcon from "../../icons-v2/WindLight.png";
import { CardHeader, Grid } from "@material-ui/core";
import LabelValue from "../core/LabelValue";
import { WindReport } from "../../api/ifis";

export default function AverageWindCard({
    wind,
}: {
    wind: WindReport | null | undefined;
}) {
    if (wind == null) {
        return null;
    }

    return (
        <Card variant="outlined">
            <CardHeader
                title="Average wind"
                avatar={<img src={WindIcon} alt="wind" />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <LabelValue label="Instant">
                            <LabelValue
                                label="Speed"
                                value={wind.instantWind?.speed}
                                slashesWhenEmpty
                                unitKey={"windSpeed"}
                            ></LabelValue>
                            <LabelValue
                                label="Direction"
                                value={wind.instantWind?.direction}
                                slashesWhenEmpty
                                unitKey={"windDirection"}
                            ></LabelValue>
                        </LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LabelValue label="2 min">
                            <LabelValue
                                label="Speed"
                                value={wind.averageWind2Min?.speed}
                                slashesWhenEmpty
                                unitKey={"windSpeed"}
                            ></LabelValue>
                            <LabelValue
                                label="Direction"
                                value={wind.averageWind2Min?.direction}
                                slashesWhenEmpty
                                unitKey={"windDirection"}
                            ></LabelValue>
                        </LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LabelValue label="10 min">
                            <LabelValue
                                label="Speed"
                                value={wind.averageWind10Min?.speed}
                                slashesWhenEmpty
                                unitKey={"windSpeed"}
                            ></LabelValue>
                            <LabelValue
                                label="Direction"
                                value={wind.averageWind10Min?.direction}
                                slashesWhenEmpty
                                unitKey={"windDirection"}
                            ></LabelValue>
                        </LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                    {wind.averageWind10Min?.directionRange && (
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Direction Max / Min"
                                value={`${valueOrSlashes(
                                    wind.averageWind10Min.directionRange
                                        .minDirection
                                )} V ${valueOrSlashes(
                                    wind.averageWind10Min.directionRange
                                        .maxDirection
                                )}`}
                            ></LabelValue>
                        </Grid>
                    )}
                    {wind.gust && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <LabelValue
                                    label="Gust speed"
                                    value={wind.gust.speed}
                                    unitKey={"windSpeed"}
                                ></LabelValue>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <LabelValue
                                    label="Gust direction"
                                    value={wind.gust.direction}
                                    unitKey={"windDirection"}
                                ></LabelValue>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
}
