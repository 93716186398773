import LabelValue from "../core/LabelValue";
import Grid from "@material-ui/core/Grid";
import { getCoordinatesDisplayText } from "../../utils/coordinates";
import { getTimestampDisplayText } from "../../utils/time";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import AircraftIcon from "../../icons-v2/AirTraffic.png";
import { FlightMessageDto } from "../../api/ifis";

export default function AircraftDetails({
    sourceDetails,
}: {
    sourceDetails: FlightMessageDto;
}) {
    return (
        <div>
            <Card variant="outlined">
                <CardHeader
                    avatar={<img src={AircraftIcon} alt="aircraft" />}
                    title="Aircraft details"
                ></CardHeader>
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Aircraft id"
                                value={sourceDetails.aircraftHexCode}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Callsign"
                                value={sourceDetails.callsign}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Altitude"
                                value={sourceDetails.altitude}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Ground Speed"
                                value={sourceDetails.groundSpeed}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Coordinates"
                                value={getCoordinatesDisplayText(
                                    sourceDetails.lat,
                                    sourceDetails.lon
                                )}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Track"
                                value={sourceDetails.track}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Vertical Rate"
                                value={sourceDetails.verticalRate}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Squawk"
                                value={sourceDetails.squawk}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Alert"
                                value={sourceDetails.alert}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Emergency"
                                value={sourceDetails.emergency}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="SPI"
                                value={sourceDetails.spi}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Is On Ground"
                                value={sourceDetails.isOnGround}
                            ></LabelValue>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Timestamp"
                                value={getTimestampDisplayText(
                                    sourceDetails.timestamp
                                )}
                            ></LabelValue>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}
