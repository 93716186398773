import { GeoJSONSource, Map, SymbolLayout } from "mapbox-gl";
import { addSymbolLayer } from "./addSymbolLayer";

export type ClusterOptions = {
    clusterColor: string;
    clusterIcon?: string;
    clusterIconLayout?: SymbolLayout;
    clusterRadius?: number;
    clusterMaxZoom?: number;
    clusterIconWidth?: number;
    clusterIconHeight?: number;
};

export function addClusterLayer(
    map: Map,
    sourceName: string,
    {
        clusterColor,
        clusterIcon,
        clusterIconLayout,
        clusterIconWidth,
        clusterIconHeight,
    }: ClusterOptions
) {
    if (map == null) {
        return;
    }

    const layerName = sourceName + "-cluster";

    map.addLayer({
        id: layerName,
        type: "circle",
        source: sourceName,
        filter: ["has", "point_count"],
        paint: {
            "circle-color": clusterColor,
            "circle-radius": 16,
        },
    });

    map.on("click", layerName, (e) => {
        if (e.originalEvent.cancelBubble) {
            return;
        }
        const features = map.queryRenderedFeatures(e.point, {
            layers: [layerName],
        });
        if (features.length === 0) return;

        const clusterId = features[0].properties?.cluster_id;
        if (clusterId == null) return;

        const source = map.getSource(sourceName) as GeoJSONSource;
        if (source == null) return;

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.easeTo({
                center: (features[0].geometry as any).coordinates,
                zoom: zoom,
            });
        });
    });

    if (clusterIcon != null) {
        addSymbolLayer(map, {
            sourceName,
            size: 20,
            width: clusterIconWidth,
            height: clusterIconHeight,
            layerPrefix: "-cluster",
            icon: clusterIcon,
            layout: {
                "icon-allow-overlap": true,
                ...clusterIconLayout,
            },
            filter: ["has", "point_count"],
        });
    }
}
