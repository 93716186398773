import { useState, useEffect, useCallback } from "react";
import { useApi } from "../contexts/ApiContext";

export default function useSystemStatus() {
    const [systemStatus, setSystemStatus] = useState<any>({
        healthy: true,
        startTime: null,
    });
    const pollInterval = 60000;
    const { getJson } = useApi();

    const getSystemStatus = useCallback(async () => {
        return await getJson(`/status`);
    }, [getJson]);

    useEffect(() => {
        const timerId = setInterval(() => {
            getSystemStatus().then(setSystemStatus);
        }, pollInterval);

        getSystemStatus().then(setSystemStatus);

        return () => clearInterval(timerId);
    }, [getSystemStatus]);

    return systemStatus;
}
