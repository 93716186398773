export const getMaxMovement = (helideckMovement: any, isNight: boolean) => {
    const maxRollLeft = isNight
        ? helideckMovement.maxRollLeftNight
        : helideckMovement.maxRollLeftDay;
    const maxRollRight = isNight
        ? helideckMovement.maxRollRightNight
        : helideckMovement.maxRollRightDay;
    const maxPitchDown = isNight
        ? helideckMovement.maxPitchDownNight
        : helideckMovement.maxPitchDownDay;
    const maxPitchUp = isNight
        ? helideckMovement.maxPitchUpNight
        : helideckMovement.maxPitchUpDay;
    const maxInclination = isNight
        ? helideckMovement.maxInclinationNight
        : helideckMovement.maxInclinationDay;
    const maxAvgHeaveRate = isNight
        ? helideckMovement.shrNight
        : helideckMovement.shrDay;

    const maxRoll = Math.max(Math.abs(maxRollLeft), Math.abs(maxRollRight));
    const maxPitch = Math.max(Math.abs(maxPitchDown), Math.abs(maxPitchUp));

    return {
        maxRoll: maxRoll?.toFixed(1),
        maxPitch: maxPitch?.toFixed(1),
        maxInclination: maxInclination?.toFixed(1),
        maxAvgHeaveRate: maxAvgHeaveRate?.toFixed(1),
    };
};

export const getLimits = (
    helideckDetails: any,
    isNight: boolean,
    isHeavy: boolean
) => {
    const rollAndPitch = isNight
        ? isHeavy
            ? helideckDetails.rollPitchNightHeavyLimit
            : helideckDetails.rollPitchNightMediumLimit
        : isHeavy
        ? helideckDetails.rollPitchDayHeavyLimit
        : helideckDetails.rollPitchDayMediumLimit;

    const inclination = isNight
        ? isHeavy
            ? helideckDetails.inclinationNightHeavyLimit
            : helideckDetails.inclinationNightMediumLimit
        : isHeavy
        ? helideckDetails.inclinationDayHeavyLimit
        : helideckDetails.inclinationDayMediumLimit;

    const avgHeaveRate = isNight
        ? isHeavy
            ? helideckDetails.shrNightHeavyLimit
            : helideckDetails.shrNightMediumLimit
        : isHeavy
        ? helideckDetails.shrDayHeavyLimit
        : helideckDetails.shrDayMediumLimit;

    return {
        rollAndPitch: rollAndPitch?.toFixed(1),
        inclination: inclination?.toFixed(1),
        avgHeaveRate: avgHeaveRate?.toFixed(1),
    };
};
