import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    RedirectRequest,
} from "@azure/msal-browser";

const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_susi",
    },
    authorities: {
        signUpSignIn: {
            authority:
                "https://mmaviationb2c.b2clogin.com/mmaviationb2c.onmicrosoft.com/B2C_1_susi",
        },
    },
    authorityDomain: "mmaviationb2c.b2clogin.com",
};

const msalConfig = {
    auth: {
        clientId: "3e0aa7f4-63d1-4517-8246-3174cbda55f3",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest: RedirectRequest = {
    scopes: ["openid", "https://mmaviationb2c.onmicrosoft.com/ifis-lmc/read"],
};

export const tokenRequest = {
    scopes: ["https://mmaviationb2c.onmicrosoft.com/ifis-lmc/read"],
    forceRefresh: false,
};

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const selectAccount = () => {
    const currentAccounts = msalInstance.getAllAccounts();

    if (currentAccounts.length < 1) {
        return;
    }

    if (currentAccounts.length === 1) {
        msalInstance.setActiveAccount(currentAccounts[0]);
        return;
    }

    const accounts = currentAccounts.filter(
        (account) =>
            account.homeAccountId
                .toUpperCase()
                .includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
            (account?.idTokenClaims as any).iss
                ?.toUpperCase()
                .includes(b2cPolicies.authorityDomain.toUpperCase()) &&
            (account.idTokenClaims as any)?.aud === msalConfig.auth.clientId
    );

    if (accounts.length < 1) {
        return;
    }

    if (accounts.length === 1) {
        msalInstance.setActiveAccount(accounts[0]);
        return;
    }

    if (
        accounts.every(
            (account) => account.localAccountId === accounts[0].localAccountId
        )
    ) {
        msalInstance.setActiveAccount(accounts[0]);
        return;
    }

    msalInstance.logoutRedirect();
};

selectAccount();

export default msalInstance;
