import { Button, useTheme, useMediaQuery } from "@material-ui/core";
import { useSettings } from "../../contexts/SettingsContext";
import { makeStyles } from "@material-ui/core/styles";
import { FocusValueNames } from "../../utils/constants";
import {
    useAppInsightsContext,
    useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "auto",
        left: "auto",
        bottom: 8,
        right: 60,
        display: "flex",
    },
    buttonSelected: {
        border: "2px solid",
        color: "black",
        borderColor: "black",
        marginLeft: -2,
        borderRadius: 0,
        fontWeight: 600,
    },
    button: {
        border: "2px solid",
        color: "black",
        borderColor: "black",
        marginLeft: -2,
        borderRadius: 0,
        fontWeight: 600,
    },
}));

const FocusValueControl = () => {
    const { focus } = useSettings();
    const classes = useStyles();
    const theme = useTheme();
    const isMedium = useMediaQuery(theme.breakpoints.up("sm"));
    const appInsights = useAppInsightsContext();
    const focusValueClicked = useTrackEvent(
        appInsights,
        "Focus value clicked",
        { focus: focus.focusValue }
    );

    const handleFocusValueClick = (value: FocusValueNames) => {
        focusValueClicked({ focus: value });
        focus.setFocusValue(value);
    };

    return (
        <div className={classes.root}>
            <Button
                variant="contained"
                className={
                    focus.focusValue === FocusValueNames.cb
                        ? classes.buttonSelected
                        : classes.button
                }
                style={{
                    paddingLeft: isMedium ? 16 : 12,
                    paddingRight: isMedium ? 16 : 12,
                }}
                onClick={() => handleFocusValueClick(FocusValueNames.cb)}
                color={
                    focus.focusValue === FocusValueNames.cb
                        ? "primary"
                        : "default"
                }
            >
                CB
            </Button>
            <Button
                variant="contained"
                className={
                    focus.focusValue === FocusValueNames.dew
                        ? classes.buttonSelected
                        : classes.button
                }
                style={{
                    paddingLeft: isMedium ? 16 : 12,
                    paddingRight: isMedium ? 16 : 12,
                }}
                color={
                    focus.focusValue === FocusValueNames.dew
                        ? "primary"
                        : "default"
                }
                onClick={() => handleFocusValueClick(FocusValueNames.dew)}
            >
                Dew
            </Button>
            <Button
                variant="contained"
                className={
                    focus.focusValue === FocusValueNames.wind
                        ? classes.buttonSelected
                        : classes.button
                }
                style={{
                    paddingLeft: isMedium ? 16 : 12,
                    paddingRight: isMedium ? 16 : 12,
                }}
                color={
                    focus.focusValue === FocusValueNames.wind
                        ? "primary"
                        : "default"
                }
                onClick={() => handleFocusValueClick(FocusValueNames.wind)}
            >
                Wind
            </Button>
            <Button
                variant="contained"
                className={
                    focus.focusValue === FocusValueNames.qnh
                        ? classes.buttonSelected
                        : classes.button
                }
                style={{
                    paddingLeft: isMedium ? 16 : 12,
                    paddingRight: isMedium ? 16 : 12,
                }}
                color={
                    focus.focusValue === FocusValueNames.qnh
                        ? "primary"
                        : "default"
                }
                onClick={() => handleFocusValueClick(FocusValueNames.qnh)}
            >
                QNH
            </Button>
        </div>
    );
};

export default FocusValueControl;
