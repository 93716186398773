export default function useLocalStorage(
    key: string
): [(value: any) => void, () => any] {
    const saveObject = (value: any) => {
        if (value == null) {
            return;
        }
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    const getObject = () => {
        const item = window.localStorage.getItem(key);
        return item != null ? JSON.parse(item) : null;
    };

    return [saveObject, getObject];
}
