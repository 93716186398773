import React, { useState } from "react";
import useSiteReports from "../../hooks/useSiteReports";
import useMarineTraffic from "../../hooks/useMarineTraffic";
import useAirTraffic from "../../hooks/useAirTraffic";
import useLightning from "../../hooks/useLightning";
import Map from "./Map";
import { DataSourcesService, DataSourceType } from "../../hooks/useDataSources";
import { useSettings } from "../../contexts/SettingsContext";
import ZoomControl from "./ZoomControl";
import LocationTrackingButton from "../menu/LocationTrackingButton";
import FocusValueControl from "./FocusValueControl";
import { DistrictType } from "./DistrictPopup";
import { DataSource } from "../../api/ifis";
import Logo from "../branding/Logo";
import useViewArea from "../../hooks/useViewArea";
import AggregatedAreaDetails from "../sourceDetails/AggregatedAreaDetails";
import useWebcams from "../../hooks/useWebcams";

type MapContainerProps = {
    dataSourcesService: DataSourcesService;
    onSourceSelected: (source: DataSource | undefined) => void;
    onDistrictChanged: (district: DistrictType | null) => void;
};

export type Point = {
    lat: number;
    lon: number;
};

const MapContainer = ({
    dataSourcesService,
    onSourceSelected,
    onDistrictChanged,
}: MapContainerProps) => {
    const { viewArea, updateViewArea, shouldLoad, updateLoadedArea } =
        useViewArea();
    const requestArea = {
        lat: viewArea.lat,
        lon: viewArea.lon,
        radius: Math.round(
            viewArea.radius + Math.max(viewArea.radius * 0.2, 5)
        ),
        xMin: viewArea.xMin,
        yMin: viewArea.yMin,
        xMax: viewArea.xMax,
        yMax: viewArea.yMax,
    };

    const siteReports = useSiteReports(
        dataSourcesService.isDisplayed(DataSourceType.Weather),
        shouldLoad,
        requestArea
    );
    const siteReportsWithHelidecks = useSiteReports(
        dataSourcesService.isDisplayed(DataSourceType.Helideck),
        shouldLoad,
        requestArea,
        true
    );
    const marineVessels = useMarineTraffic(
        dataSourcesService.isDisplayed(DataSourceType.MarineTraffic),
        shouldLoad,
        requestArea
    );
    const aircrafts = useAirTraffic(
        dataSourcesService.isDisplayed(DataSourceType.AirTraffic)
    );
    const lightning = useLightning(
        dataSourcesService.isDisplayed(DataSourceType.Lightning)
    );
    const webcams = useWebcams(
        dataSourcesService.isDisplayed(DataSourceType.RoadCamera)
    );

    const { mapStyleService, zoom } = useSettings();

    if (shouldLoad) {
        updateLoadedArea(requestArea, viewArea, zoom.zoomLevel);
    }

    const [isTracking, setIsTracking] = useState(false);
    const [isTrackingHeading, setIsTrackingHeading] = useState(false);

    return (
        <div
            style={{
                width: "100%",
                height: window.innerHeight * 0.7,
                position: "absolute",
                top: 0,
                zIndex: -1,
            }}
        >
            <Logo />
            <Map
                viewLon={viewArea.lon}
                viewLat={viewArea.lat}
                siteReports={siteReports}
                siteReportsWithHelidecks={siteReportsWithHelidecks}
                aircrafts={aircrafts}
                marineVessels={marineVessels}
                lightning={lightning}
                webcams={webcams}
                areObstaclesOn={dataSourcesService.isDisplayed(
                    DataSourceType.Obstacle
                )}
                mapStyle={mapStyleService.mapStyle.url}
                isTracking={isTracking}
                isTrackingHeading={isTrackingHeading}
                stopTracking={() => {
                    setIsTrackingHeading(false);
                    setIsTracking(false);
                }}
                onViewportChanged={updateViewArea}
                onSourceSelected={onSourceSelected}
                onDistrictChanged={onDistrictChanged}
            />
            <LocationTrackingButton
                onClick={() => {
                    if (!isTracking) {
                        setIsTracking(true);
                    } else if (!isTrackingHeading) {
                        setIsTrackingHeading(true);
                    } else {
                        setIsTrackingHeading(false);
                        setIsTracking(false);
                    }
                }}
                isTracking={isTracking}
                isTrackingHeading={isTrackingHeading}
            />
            <ZoomControl />
            <FocusValueControl />
            <AggregatedAreaDetails lat={viewArea.lat} lon={viewArea.lon} />
        </div>
    );
};

export default React.memo(MapContainer);
