import { useState, useEffect, useCallback } from "react";
import { tagWithDataSourceType } from "../api/api";
import { useApi } from "../contexts/ApiContext";
import { DataSourceType } from "./useDataSources";

export default function useAirTraffic(enabled: boolean) {
    const [aircrafts, setAircrafts] = useState<any[]>([]);
    const { getJson } = useApi();

    const updateAirTraffic = useCallback(async () => {
        const response = await getJson(`/traffic/air`);
        if (!response) return;
        setAircrafts(
            tagWithDataSourceType(response, DataSourceType.AirTraffic)
        );
    }, [getJson, setAircrafts]);

    useEffect(() => {
        if (!enabled) {
            setAircrafts([]);
            return;
        }

        updateAirTraffic();

        const pollInterval = 5000; //5sec
        const timerId = setInterval(() => {
            updateAirTraffic();
        }, pollInterval);

        return () => clearInterval(timerId);
    }, [enabled, updateAirTraffic]);

    return aircrafts;
}
