import { GeoJSONSource, Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { addSymbolLayer } from "../addSymbolLayer";
import HelideckIcon from "../../../../icons-v2/Helideck.png";
import { mapToFeatureCollection } from "../../../../utils/geojsonMapping";
import { addGeoJsonSource } from "../addGeoJsonSource";
import { SiteReportCompactDto } from "../../../../api/ifis";

export default function useHelideckSource(
    siteReportsWithHelidecks: SiteReportCompactDto[],
    map: Map | null,
    loadedMapStyle: string | null,
    onSourceSelected: (source: SiteReportCompactDto | undefined) => void
) {
    const helidecksRef = useRef<SiteReportCompactDto[]>();

    useEffect(() => {
        helidecksRef.current = siteReportsWithHelidecks;
    }, [siteReportsWithHelidecks]);

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const sourceName = "helidecks";
        if (map.getSource(sourceName)) {
            return;
        }

        const onClick = (e: any) => {
            if (e.features == null) {
                return;
            }
            onSourceSelected(
                helidecksRef.current?.find(
                    (x) => x.name === e.features[0].properties?.id
                )
            );
        };

        addGeoJsonSource(map, sourceName);

        // add icon layer
        addSymbolLayer(map, {
            sourceName: sourceName,
            icon: HelideckIcon,
            size: 20,
            layout: {
                "icon-allow-overlap": true,
            },
            onClick,
        });
    }, [map, loadedMapStyle, siteReportsWithHelidecks, onSourceSelected]);

    useEffect(() => {
        if (map == null) return;
        const source = map.getSource("helidecks") as GeoJSONSource;
        if (source == null) return;
        source.setData(
            mapToFeatureCollection(siteReportsWithHelidecks, "name")
        );
    }, [map, siteReportsWithHelidecks, loadedMapStyle]);
}
