import { debounce } from "@material-ui/core";
import { useState, useEffect, useCallback, useMemo } from "react";
import { tagWithDataSourceType } from "../api/api";
import { SiteReportCompactDto } from "../api/ifis";
import { useApi } from "../contexts/ApiContext";
import { useSettings } from "../contexts/SettingsContext";
import { DataSourceType } from "./useDataSources";
import { GeoArea } from "./useViewArea";

type SiteReportQuery = {
    lat: number;
    lon: number;
    distanceInKm: number;
    hasHeliDecks: boolean;
    minQuality: string | undefined;
};

export default function useSiteReports(
    enabled: boolean,
    shouldLoad: boolean,
    requestArea: GeoArea,
    hasHeliDecks: boolean = false
) {
    const [siteReports, setSiteReports] = useState<SiteReportCompactDto[]>([]);
    const { zoom } = useSettings();
    const { getJson } = useApi();

    const updateSiteReports = useCallback(
        async ({ distanceInKm, lat, lon, minQuality }: SiteReportQuery) => {
            const response = await getJson(
                `/meteorology/site-report?km=${distanceInKm}&lat=${lat}&lon=${lon}${
                    hasHeliDecks ? "&features=helideck" : ""
                }${minQuality ? "&minQuality=" + minQuality : ""}`
            );
            if (!response) return;
            setSiteReports(
                tagWithDataSourceType(response, DataSourceType.Weather)
            );
        },
        [getJson, hasHeliDecks, setSiteReports]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateSiteReports = useCallback(
        debounce((query: SiteReportQuery) => updateSiteReports(query), 500),
        []
    );

    const query: SiteReportQuery = useMemo(() => {
        const getMinQualityForZoom = () => {
            if (zoom.zoomLevel >= 8 || hasHeliDecks) return undefined;
            if (zoom.zoomLevel >= 7) return "OfficialMet";
            return "Aviation";
        };

        return {
            distanceInKm: requestArea.radius,
            lat: requestArea.lat,
            lon: requestArea.lon,
            hasHeliDecks,
            minQuality: getMinQualityForZoom(),
        };
    }, [
        hasHeliDecks,
        requestArea.lat,
        requestArea.lon,
        requestArea.radius,
        zoom.zoomLevel,
    ]);

    if (enabled && shouldLoad) {
        debouncedUpdateSiteReports(query);
    }

    useEffect(() => {
        if (!enabled) {
            setSiteReports([]);
            return;
        }

        const pollInterval = 60000; //1min
        const timerId = setInterval(
            () => updateSiteReports(query),
            pollInterval
        );

        return () => clearInterval(timerId);
    }, [enabled, query, updateSiteReports]);

    return siteReports;
}
