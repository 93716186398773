import Grid from "@material-ui/core/Grid";
import LabelValue from "../core/LabelValue";
import { getCoordinatesDisplayText } from "../../utils/coordinates";
import HeliLandingSiteIcon from "../../icons-v2/HelipadLight.png";
import LandingSiteIcon from "../../icons-v2/LandingStripLight.png";
// @ts-ignore
import imageToBase64 from "image-to-base64/browser";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { LandingSite, UserImage } from "../../api/ifis";
import useUserImages from "../../hooks/useUserImages";
import Carousel from "react-material-ui-carousel";
import { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginLeft: "auto",
            color: theme.palette.primary.light,
        },
    })
);

export default function LandingSiteDetails({
    sourceDetails,
}: {
    sourceDetails: LandingSite;
}) {
    const [uploading, setUploading] = useState<boolean>(false);
    const { images, uploadImage } = useUserImages(
        sourceDetails.id,
        uploading === false
    );
    const classes = useStyles();

    const handleUploadClick = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        setUploading(true);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            imageToBase64(reader.result).then((response: string) => {
                uploadImage({
                    imageBase64: response,
                    landingSiteId: sourceDetails.id,
                    mimeType: file.type,
                }).then(() => {
                    setUploading(false);
                });
            });
        };
    };

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={
                    sourceDetails.type === "Helikopter" ? (
                        <img src={HeliLandingSiteIcon} alt="heli" />
                    ) : (
                        <img src={LandingSiteIcon} alt="landing strip" />
                    )
                }
                title="Landing site details"
            ></CardHeader>
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Type"
                            value={sourceDetails.type}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Position"
                            value={getCoordinatesDisplayText(
                                sourceDetails.position.coordinates[1],
                                sourceDetails.position.coordinates[0]
                            )}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="IATA"
                            value={sourceDetails.iata}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="ICAO"
                            value={sourceDetails.icao}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Name"
                            value={sourceDetails.name}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Traffic"
                            value={sourceDetails.trafficType}
                        ></LabelValue>
                    </Grid>
                </Grid>
                <Carousel swipe={true} animation="slide" autoPlay={false}>
                    {images ? (
                        images.map((x: UserImage) => (
                            <img
                                style={{
                                    maxHeight: "50vh",
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "scale-down",
                                }}
                                key={x.id}
                                src={x.url}
                                alt="landing site"
                            />
                        ))
                    ) : (
                        <CircularProgress />
                    )}
                </Carousel>
            </CardContent>
            <CardActions style={{ flexDirection: "row-reverse" }}>
                {!uploading ? (
                    <>
                        <input
                            accept="image/*"
                            id="userImageInput"
                            type="file"
                            onChange={(event: any) => handleUploadClick(event)}
                            style={{ display: "none" }}
                        />
                        <label htmlFor="userImageInput">
                            <Button
                                component="span"
                                size="small"
                                className={classes.button}
                            >
                                Upload picture
                            </Button>
                        </label>
                    </>
                ) : (
                    <CircularProgress />
                )}
            </CardActions>
        </Card>
    );
}
