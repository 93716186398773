import { IDBPDatabase, openDB } from "idb";
import { useEffect, useState } from "react";

export default function useDb() {
    const [db, setDb] = useState<any>();
    const tilesDb: string = "tilesDb";
    const tileStoreName: string = "tiles";

    useEffect(() => {
        const openDatabase = async () => {
            if (!db) {
                const openedDb = await openDB(tilesDb, 1, {
                    upgrade: (db: IDBPDatabase) => {
                        if (!db.objectStoreNames.contains(tileStoreName)) {
                            db.createObjectStore(tileStoreName);
                        }
                    },
                });
                setDb(openedDb);
            }
        };
        openDatabase();
    }, [db]);

    return { db, tileStoreName };
}
