import { useCallback } from "react";
import useDb from "./useDb";

export default function useTileStorage() {
    const { db, tileStoreName } = useDb();

    const getStoredTile = useCallback(
        (x: number, y: number, z: number) => {
            return !db
                ? Promise.reject("uninitialized db")
                : db.get(tileStoreName, [x, y, z]);
        },
        [db, tileStoreName]
    );

    const storeTile = (x: number, y: number, z: number, tile: any) => {
        return !db
            ? Promise.reject("uninitialized db")
            : db.put(tileStoreName, tile, [x, y, z]);
    };

    const clearTileStore = () => {
        return !db
            ? Promise.reject("uninitialized db")
            : db.clear(tileStoreName);
    };

    return { getStoredTile, clearTileStore, storeTile };
}
