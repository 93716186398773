import { useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";

export default function useZoom(zoomPresets: number[]) {
    const [saveObject, getObject] = useLocalStorage("zoom");
    const [zoomLevel, setZoomLevelState] = useState(
        getObject() || zoomPresets[0]
    );
    const [currZoomPreset, setCurrZoomPreset] = useState(0);

    useEffect(() => {
        let currZoomPreset = 0;
        let closestDist = 100;
        for (let i = 0; i < zoomPresets.length; i++) {
            const dist = Math.abs(zoomLevel - zoomPresets[i]);
            if (dist < closestDist) {
                closestDist = dist;
                currZoomPreset = i;
            }
        }
        setCurrZoomPreset(currZoomPreset);
    }, [zoomLevel, zoomPresets]);

    const setZoomLevel = (zoom: number) => {
        setZoomLevelState(zoom);
        saveObject(zoom);
    };

    return { zoomLevel, setZoomLevel, zoomPresets, currZoomPreset };
}
