import { useState } from "react";
import useLocalStorage from "./useLocalStorage";

export type FavoriteHookType = {
    favorites: any[];
    removeFromFavorites: (favorite: any) => void;
    addToFavorites: (favorite: any) => void;
    hasFavorite: (favorite: any) => boolean;
};

export default function useFavorites(): FavoriteHookType {
    const [saveObject, getObject] = useLocalStorage("favorites");
    const [favorites, setFavorites] = useState<any[]>(getObject() || []);

    const removeFromFavorites = (favorite: any) => {
        updateFavorites(
            favorites.filter(
                ({ helideckReport }) => helideckReport !== favorite
            )
        );
    };

    const addToFavorites = (favorite: any) => {
        updateFavorites([...favorites, favorite]);
    };

    const hasFavorite = (favorite: any) => {
        return favorites.some(
            ({ helideckReport }) =>
                helideckReport.helideckDetails.id ===
                favorite.helideckDetails.id
        );
    };

    const updateFavorites = (newFavorites: any[]) => {
        setFavorites(newFavorites);
        saveObject(newFavorites);
    };

    return {
        favorites,
        hasFavorite,
        addToFavorites,
        removeFromFavorites,
    };
}
