import mapboxgl, { Map } from "mapbox-gl";
import { useEffect } from "react";

export default function useObstacleSource(
    areObstaclesOn: boolean,
    map: Map | null,
    loadedMapStyle: string | null
) {
    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }
    }, [map, loadedMapStyle]);

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const handleClick = (e: any) => {
            // Copy coordinates array.
            const lonLat = map.unproject([e.point.x, e.point.y]);

            new mapboxgl.Popup()
                .setLngLat(lonLat)
                .setHTML(
                    `<div style="color: black;"><p>Elevation: ${e.features[0].properties.elevation}m</p><p>Type: ${e.features[0].properties.type}</p></div>`
                )
                .addTo(map);
        };

        map.setLayoutProperty(
            "obstacles-base",
            "visibility",
            areObstaclesOn ? "visible" : "none"
        );

        map.setLayoutProperty(
            "obstacles-dash",
            "visibility",
            areObstaclesOn ? "visible" : "none"
        );

        map.setLayoutProperty(
            "obstacles-points",
            "visibility",
            areObstaclesOn ? "visible" : "none"
        );

        map.on("click", "obstacles-base", handleClick);
        map.on("click", "obstacles-points", handleClick);
    }, [map, loadedMapStyle, areObstaclesOn]);
}
