import NavigationIcon from "../../icons-v2/Navigation.png";
import CompassIcon from "../../icons-v2/Compass30.png";
import NorthIcon from "../../icons-v2/North.png";
import { createStyles, Button, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "absolute",
            top: "auto",
            left: "auto",
            bottom: 84,
            right: 8,
        },
        locationTrackingButton: {
            border: "2px solid",
            color: "black",
            borderColor: "black",
            borderRadius: 0,
            height: 40,
            width: 40,
            minWidth: 40,
            padding: 0,
        },
    })
);

type LocationTrackingProps = {
    isTracking: boolean;
    isTrackingHeading: boolean;
    onClick: () => void;
};

const LocationTrackingButton = ({
    onClick,
    isTracking,
    isTrackingHeading,
}: LocationTrackingProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Button
                data-sample-id="TrackingButton"
                data-sample-tracking={isTracking}
                className={classes.locationTrackingButton}
                size="medium"
                color={isTracking ? "secondary" : "default"}
                variant="contained"
                onClick={onClick}
            >
                {isTracking ? (
                    isTrackingHeading ? (
                        <img src={CompassIcon} alt="compass" />
                    ) : (
                        <img src={NorthIcon} alt="north" />
                    )
                ) : (
                    <img src={NavigationIcon} alt="navigation" />
                )}
            </Button>
        </div>
    );
};

export default LocationTrackingButton;
