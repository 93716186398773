import { GeoJSONSource, Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import AircraftIcon from "../../../../icons-v2/Airplane.png";
import { addSymbolLayer } from "../addSymbolLayer";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { renderFlightPaths } from "../../../../utils/flightRenderer";
import { emptyFeatureCollection } from "../../../../utils/geojsonMapping";
import { addGeoJsonSource } from "../addGeoJsonSource";
import { FlightMessageDto } from "../../../../api/ifis";
import { valueOrSlashes } from "../../../../utils/constants";

export default function useAircraftSource(
    aircrafts: FlightMessageDto[],
    map: Map | null,
    loadedMapStyle: string | null,
    onSourceSelected: (source: FlightMessageDto | undefined) => void
) {
    const aircraftsRef = useRef<FlightMessageDto[]>();

    useEffect(() => {
        aircraftsRef.current = aircrafts;
    }, [aircrafts]);

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const sourceName = "air-traffic";
        if (map.getSource(sourceName)) {
            return;
        }

        const onClick = (e: any) => {
            if (e.features == null) {
                return;
            }
            onSourceSelected(
                aircraftsRef.current?.find(
                    (x) => x.aircraftHexCode === e.features[0].properties?.id
                )
            );
        };

        addGeoJsonSource(map, "flight-path");

        map.addLayer({
            id: "flight-path-line",
            type: "line",
            source: "flight-path",
            paint: {
                "line-color": ["get", "color"],
                "line-width": 2,
            },
        });

        map.addLayer({
            id: "flight-path-line-blur",
            type: "line",
            source: "flight-path",
            paint: {
                "line-color": ["get", "color"],
                "line-width": 10,
                "line-blur": 8,
            },
        });

        addGeoJsonSource(map, sourceName);

        addSymbolLayer(map, {
            sourceName: sourceName,
            icon: AircraftIcon,
            sdf: true,
            layout: {
                "icon-rotate": ["get", "rotation"],
                "icon-rotation-alignment": "map",
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "icon-size": 1.4,
                "text-field": [
                    "format",
                    ["get", "name"],
                    "\n",
                    ["get", "altitude"],
                    { "font-scale": 0.8 },
                    "ft",
                    { "font-scale": 0.8 },
                ],
                "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                "text-size": 14,
                "text-letter-spacing": 0.05,
                "text-anchor": "left",
                "text-offset": [0.7, 0],
            },
            paint: {
                "icon-color": ["get", "color"],
                "text-color": "#424242",
                "text-halo-color": "#e3e3e3",
                "text-halo-width": 6,
                "text-opacity": 0.8,
            },
            onClick,
        });
    }, [aircrafts, map, loadedMapStyle, onSourceSelected]);

    useEffect(() => {
        if (map == null) {
            return;
        }

        const aircraftSource = map.getSource("air-traffic") as GeoJSONSource;
        const flightpathSource = map.getSource("flight-path") as GeoJSONSource;

        if (aircraftSource == null || flightpathSource == null) {
            return;
        }

        if (aircrafts) {
            const aircraftFeatures = {
                type: "FeatureCollection",
                features: aircrafts.map((a: any) => {
                    return {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [a.lon, a.lat],
                        },
                        properties: {
                            id: a.aircraftHexCode,
                            name: valueOrSlashes(a.callsign),
                            rotation: +a.track,
                            altitude: a.altitude,
                        },
                    };
                }),
            } as FeatureCollection<Geometry, GeoJsonProperties>;
            aircraftSource.setData(aircraftFeatures);
            flightpathSource.setData(
                renderFlightPaths(aircrafts) as FeatureCollection<
                    Geometry,
                    GeoJsonProperties
                >
            );
        } else {
            aircraftSource.setData(emptyFeatureCollection());
            flightpathSource.setData(emptyFeatureCollection());
        }
    }, [map, aircrafts, loadedMapStyle]);
}
