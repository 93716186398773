import { GeoJSONSource, Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { addSymbolLayer } from "../addSymbolLayer";
import LightningIcon from "../../../../icons-v2/Lightning.png";
import { mapToFeatureCollection } from "../../../../utils/geojsonMapping";
import { addGeoJsonSource } from "../addGeoJsonSource";
import { LightningDto } from "../../../../api/ifis";

export default function useLightningSource(
    lightning: LightningDto[],
    map: Map | null,
    loadedMapStyle: string | null,
    onSourceSelected: (source: LightningDto | undefined) => void
) {
    const lightningRef = useRef<LightningDto[]>();

    useEffect(() => {
        lightningRef.current = lightning;
    }, [lightning]);

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const sourceName = "lightning";
        if (map.getSource(sourceName)) {
            return;
        }

        const onClick = (e: any) => {
            if (e.features == null) {
                return;
            }
            onSourceSelected(
                lightningRef.current?.find(
                    (x) => x.timestamp === e.features[0].properties?.id
                )
            );
        };

        addGeoJsonSource(map, sourceName, { clusterColor: "yellow" });

        // add icon layer
        addSymbolLayer(map, {
            sourceName: sourceName,
            icon: LightningIcon,
            size: 20,
            layout: {
                "icon-allow-overlap": true,
                "icon-offset": [
                    "step",
                    ["get", "point_count"],
                    ["literal", [0, 0]],
                    2,
                    ["literal", [0, -20]],
                ],
                "icon-size": ["step", ["get", "point_count"], 1, 2, 0.8],
                "text-field": "{point_count}",
            },
            onClick,
        });
    }, [lightning, map, loadedMapStyle, onSourceSelected]);

    useEffect(() => {
        if (map == null) return;
        const source = map.getSource("lightning") as GeoJSONSource;
        if (source == null) return;
        source.setData(mapToFeatureCollection(lightning, "timestamp"));
    }, [map, lightning, loadedMapStyle]);
}
