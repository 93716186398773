import React, { useEffect, useState } from "react";
import WeatherIcon from "../icons-v2/WeatherStationLight.png";
import AircraftIcon from "../icons-v2/AirTraffic.png";
import AnchorIcon from "../icons-v2/AnchorLight.png";
import CameraIcon from "../icons-v2/CameraLight.png";
import LightningIcon from "../icons-v2/LightningLight.png";
import HelideckIcon from "../icons-v2/HelideckLight.png";
import ObstacleIcon from "../icons-v2/FlightObstaclesLight.png";
import useLocalStorage from "./useLocalStorage";

export enum DataSourceType {
    Weather = "Weather",
    AirTraffic = "AirTraffic",
    MarineTraffic = "MarineTraffic",
    RoadCamera = "RoadCamera",
    Lightning = "Lightning",
    Helideck = "Helideck",
    Obstacle = "Obstacle",
    LandingSite = "LandingSite",
}

export const allDataSourceTypes = [
    DataSourceType.Weather,
    DataSourceType.Helideck,
    DataSourceType.AirTraffic,
    DataSourceType.MarineTraffic,
    DataSourceType.RoadCamera,
    DataSourceType.Lightning,
    DataSourceType.Obstacle,
];

export type DataSourcesService = {
    isDisplayed: (dataSource: DataSourceType) => boolean;
    toggle: (dataSource: DataSourceType) => void;
    getIcon: (dataSource: DataSourceType) => any;
    getDisplayName: (dataSource: DataSourceType) => string;
};

export default function useDataSources() {
    const [saveObject, getObject] = useLocalStorage("data-sources");
    const datasourcesFromLocalStorage = getObject();
    const [displayWeather, setDisplayWeather] = useState(
        datasourcesFromLocalStorage?.displayWeather ?? true
    );
    const [displayHelidecks, setDisplayHelidecks] = useState(
        datasourcesFromLocalStorage?.displayHelidecks
    );
    const [displayAirTraffic, setDisplayAirTraffic] = useState(
        datasourcesFromLocalStorage?.displayAirTraffic
    );
    const [displayMarineTraffic, setDisplayMarineTraffic] = useState(
        datasourcesFromLocalStorage?.displayMarineTraffic
    );
    const [displayRoadCameras, setDisplayRoadCameras] = useState(
        datasourcesFromLocalStorage?.displayRoadCameras
    );
    const [displayLightning, setDisplayLightning] = useState(
        datasourcesFromLocalStorage?.displayLightning
    );
    const [displayObstacles, setDisplayObstacles] = useState(
        datasourcesFromLocalStorage?.displayObstacles
    );

    const toggleWeather = () => {
        setDisplayWeather(!displayWeather);
    };

    const toggleHelidecks = () => {
        setDisplayHelidecks(!displayHelidecks);
    };

    const toggleAirTraffic = () => {
        setDisplayAirTraffic(!displayAirTraffic);
    };

    const toggleMarineTraffic = () => {
        setDisplayMarineTraffic(!displayMarineTraffic);
    };

    const toggleRoadCameras = () => {
        setDisplayRoadCameras(!displayRoadCameras);
    };

    const toggleLightning = () => {
        setDisplayLightning(!displayLightning);
    };

    const toggleObstacles = () => {
        setDisplayObstacles(!displayObstacles);
    };

    useEffect(() => {
        saveObject({
            displayWeather,
            displayHelidecks,
            displayAirTraffic,
            displayMarineTraffic,
            displayRoadCameras,
            displayLightning,
            displayObstacles,
        });
    }, [
        displayWeather,
        displayHelidecks,
        displayAirTraffic,
        displayMarineTraffic,
        displayRoadCameras,
        displayLightning,
        displayObstacles,
        saveObject,
    ]);

    const isDisplayed = (dataSource: DataSourceType): boolean => {
        switch (dataSource) {
            case DataSourceType.Weather:
                return displayWeather;
            case DataSourceType.AirTraffic:
                return displayAirTraffic;
            case DataSourceType.Lightning:
                return displayLightning;
            case DataSourceType.MarineTraffic:
                return displayMarineTraffic;
            case DataSourceType.RoadCamera:
                return displayRoadCameras;
            case DataSourceType.Helideck:
                return displayHelidecks;
            case DataSourceType.Obstacle:
                return displayObstacles;
            default:
                return false;
        }
    };

    const getIcon = (dataSource: DataSourceType): any => {
        switch (dataSource) {
            case DataSourceType.Weather:
                return <img src={WeatherIcon} alt="weather station" />;
            case DataSourceType.AirTraffic:
                return <img src={AircraftIcon} alt="air traffic" />;
            case DataSourceType.Lightning:
                return <img src={LightningIcon} alt="lightning" />;
            case DataSourceType.MarineTraffic:
                return <img src={AnchorIcon} alt="anchor" />;
            case DataSourceType.RoadCamera:
                return <img src={CameraIcon} alt="camera" />;
            case DataSourceType.Helideck:
                return <img src={HelideckIcon} alt="helideck" />;
            case DataSourceType.Obstacle:
                return <img src={ObstacleIcon} alt="obstacle" />;
            default:
                return undefined;
        }
    };

    const toggle = (dataSource: DataSourceType): void => {
        switch (dataSource) {
            case DataSourceType.Weather:
                toggleWeather();
                break;
            case DataSourceType.AirTraffic:
                toggleAirTraffic();
                break;
            case DataSourceType.Lightning:
                toggleLightning();
                break;
            case DataSourceType.MarineTraffic:
                toggleMarineTraffic();
                break;
            case DataSourceType.RoadCamera:
                toggleRoadCameras();
                break;
            case DataSourceType.Helideck:
                toggleHelidecks();
                break;
            case DataSourceType.Obstacle:
                toggleObstacles();
                break;
        }
    };

    const getDisplayName = (dataSource: DataSourceType): string => {
        switch (dataSource) {
            case DataSourceType.Weather:
                return "Weather stations";
            case DataSourceType.AirTraffic:
                return "Air traffic";
            case DataSourceType.Lightning:
                return "Lightning";
            case DataSourceType.MarineTraffic:
                return "Marine traffic";
            case DataSourceType.RoadCamera:
                return "Road cameras";
            case DataSourceType.Helideck:
                return "Helidecks";
            case DataSourceType.Obstacle:
                return "Flight obstacles";
            default:
                return "";
        }
    };

    return { isDisplayed, toggle, getIcon, getDisplayName };
}
