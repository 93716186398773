import { getAltitudeColor } from "./altitudeColors";

export const renderFlightPaths = (flights: any[]) => {
    const featureCollection = {
        type: "FeatureCollection",
        features: [].concat.apply(
            [],
            flights
                .filter((x: any) => x.positionHistory.length >= 2)
                .map((x: any) => renderFlightPath(x))
        ),
    };
    return featureCollection;
};

const renderFlightPath = (flight: any) => {
    return flight.positionHistory
        .reduce((pairs: any[], current: any, index: number) => {
            if (index < flight.positionHistory.length - 1) {
                pairs[index] = { pos1: current };
            }

            if (index > 0) {
                pairs[index - 1].pos2 = current;
            }

            return pairs;
        }, [])
        .map((pair: any) => renderFlightSection(pair.pos1, pair.pos2));
};

const renderFlightSection = (pos1: any, pos2: any) => {
    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: [
                [pos1.lon, pos1.lat],
                [pos2.lon, pos2.lat],
            ],
        },
        properties: {
            color: getAltitudeColor(pos2.altitude),
        },
    };
};
