import { Map } from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";
import { TourStatus } from "../../DemoController";
import tour from "./demo.json";

export default function useTracking(
    map: Map | null,
    isTracking: boolean,
    isTrackingHeading: boolean
) {
    const [
        trackedLocationDemo,
        setTrackedLocation,
    ] = useState<GeolocationCoordinates | null>(null);

    const [progress, setProgress] = useState(tour.StartIndex);
    const [tourStatus, setTourStatus] = useState(TourStatus.stopped);

    const progressToNext = useCallback(() => {
        if (map == null) {
            return;
        }

        if (
            tourStatus === TourStatus.paused ||
            tourStatus === TourStatus.stopped
        ) {
            return;
        }

        setProgress((x) => {
            const { Lat, Lon, Altitude, Speed, Direction } = tour.DataPoints[x];

            setTrackedLocation({
                latitude: Lat,
                longitude: Lon,
                altitude: Altitude,
                speed: Speed,
                accuracy: 0,
                altitudeAccuracy: 0,
                heading: Direction,
            });

            if (isTracking) {
                map.easeTo({
                    duration: 1000,
                    center: {
                        lng: Lon,
                        lat: Lat,
                    },
                    bearing: isTrackingHeading ? Direction : 0,
                    easing: (t) => t,
                });
            }
            const nextStep = x + 1;

            return nextStep === tour.StopIndex ? tour.StartIndex : nextStep;
        });
    }, [isTracking, isTrackingHeading, map, tourStatus]);

    useEffect(() => {
        if (map == null) {
            return;
        }

        if (tourStatus === TourStatus.stopped) {
            return;
        }

        const intervalId = setInterval(progressToNext, 1000);

        return () => clearInterval(intervalId);
    }, [map, progressToNext, tourStatus]);

    return {
        trackedLocationDemo,
        progress,
        tour,
        tourStatus,
        setProgress,
        setTourStatus,
    };
}
