import { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import MapContainer from "./components/map/MapContainer";
import HelideckFavorites from "./components/helideck/HelideckFavorites";
import useDataSources, { DataSourceType } from "./hooks/useDataSources";
import AltitudeColorScale from "./components/AltitudeColorScale";
import AreaDetailsDialog from "./components/sourceDetails/AreaDetails";
import InvokeMenuButton from "./components/menu/InvokeMenuButton";
import MenuDrawer from "./components/menu/MenuDrawer";
import { useSettings } from "./contexts/SettingsContext";
import TransferQuality from "./components/TransferQuality";
import DistrictPopup, { DistrictType } from "./components/map/DistrictPopup";
import { DataSource } from "./api/ifis";
import {
    useAppInsightsContext,
    useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import ErrorNotification from "./components/ErrorNotification";

function App() {
    const dataSourcesService = useDataSources();
    const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
    const [selectedSource, setSelectedSource] = useState<
        DataSource | undefined
    >();
    const { showHeightBar } = useSettings();
    const [district, setDistrict] = useState<DistrictType | null>(null);

    const appInsights = useAppInsightsContext();
    const trackSourceClicked = useTrackEvent(
        appInsights,
        "Source clicked",
        selectedSource
    );

    const clearSelectedSource = () => {
        setSelectedSource(undefined);
    };

    const handleSourceSelected = (dataSource: DataSource | undefined) => {
        setSelectedSource(dataSource);
        trackSourceClicked(dataSource);
    };

    const toggleMenuDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setMenuDrawerOpen(open);
        };

    return (
        <Router>
            <Route path={["/:coords", "/"]}>
                <TransferQuality />
                <ErrorNotification />
                <MapContainer
                    dataSourcesService={dataSourcesService}
                    onSourceSelected={handleSourceSelected}
                    onDistrictChanged={setDistrict}
                />
                <AreaDetailsDialog
                    open={selectedSource != null}
                    sourceDetails={selectedSource}
                    onClose={clearSelectedSource}
                />
                <HelideckFavorites onSourceSelected={setSelectedSource} />
                {dataSourcesService.isDisplayed(DataSourceType.AirTraffic) &&
                    showHeightBar && <AltitudeColorScale />}
                <DistrictPopup
                    district={district}
                    onSourceSelected={setSelectedSource}
                    onDistrictChanged={setDistrict}
                />
                <MenuDrawer
                    open={menuDrawerOpen}
                    toggle={toggleMenuDrawer}
                    dataSourcesService={dataSourcesService}
                />
                <InvokeMenuButton onInvokeMenuClick={toggleMenuDrawer(true)} />
            </Route>
        </Router>
    );
}

export default App;
