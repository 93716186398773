import LabelValue from "../core/LabelValue";
import Grid from "@material-ui/core/Grid";
import { getCoordinatesDisplayText } from "../../utils/coordinates";
import { getTimestampDisplayText } from "../../utils/time";
import AnchorIcon from "../../icons-v2/AnchorLight.png";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { MarineVesselDto } from "../../api/ifis";

export default function MarineVesselDetails({
    sourceDetails,
}: {
    sourceDetails: MarineVesselDto;
}) {
    const getLength = (vessel: MarineVesselDto) => {
        return vessel.a && vessel.b ? +vessel.a + +vessel.b : "//";
    };

    const getWidth = (vessel: MarineVesselDto) => {
        return vessel.c && vessel.d ? +vessel.c + +vessel.d : "//";
    };

    return (
        <div>
            <Card variant="outlined">
                <CardHeader
                    avatar={<img src={AnchorIcon} alt="anchor" />}
                    title="Vessel details"
                ></CardHeader>
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="NAME"
                                value={sourceDetails.name}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="MMSI"
                                value={sourceDetails.mmsi}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Callsign"
                                value={sourceDetails.callsign}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="IMO"
                                value={sourceDetails.imo}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Coordinates"
                                value={getCoordinatesDisplayText(
                                    sourceDetails.lat,
                                    sourceDetails.lon
                                )}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Ship type"
                                value={sourceDetails.shipType}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Country"
                                value={sourceDetails.country}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Destination"
                                value={sourceDetails.destination}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Eta"
                                value={sourceDetails.eta}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Heading"
                                value={sourceDetails.heading}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Draught"
                                value={sourceDetails.draught}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Speed over ground (kn)"
                                value={sourceDetails.sog}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Rate of turn"
                                value={sourceDetails.rot}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Course over ground (angle)"
                                value={sourceDetails.cog}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Length (m)"
                                value={getLength(sourceDetails)}
                            ></LabelValue>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Width (m)"
                                value={getWidth(sourceDetails)}
                            ></LabelValue>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LabelValue
                                label="Timestamp"
                                value={getTimestampDisplayText(
                                    sourceDetails.timeStamp
                                )}
                            ></LabelValue>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}
