import React from "react";
import WeatherReport from "./WeatherReport";
import HelideckReport from "../helideck/HelideckReport/HelideckReport";
import Tabs from "@material-ui/core/Tabs";
import { Tab } from "@material-ui/core";
import DetailsTabPanel from "../core/DetailsTabPanel";
import WeatherIcon from "../../icons-v2/WeatherStationLight.png";
import HelideckIcon from "../../icons-v2/HelideckLight.png";
import { SiteReportDto } from "../../api/ifis";

export default function SiteReport({
    siteReport,
}: {
    siteReport: SiteReportDto;
}) {
    const [activeTab, setActiveTab] = React.useState(0);
    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newTabIndex: number
    ) => {
        setActiveTab(newTabIndex);
    };

    if (siteReport == null) {
        return null;
    }

    const helideckReports = siteReport.helideckReports || [];

    return (
        <div>
            <Tabs value={activeTab} onChange={handleTabChange}>
                {helideckReports.map((x: any) => (
                    <Tab
                        key={x.helideckDetails.id}
                        icon={<img src={HelideckIcon} alt="helideck icon" />}
                    />
                ))}

                <Tab icon={<img src={WeatherIcon} alt="station icon" />} />
            </Tabs>

            <DetailsTabPanel
                activeTab={activeTab}
                index={helideckReports.length}
            >
                <WeatherReport siteReport={siteReport} />
            </DetailsTabPanel>

            {helideckReports.map((x: any, index: number) => (
                <DetailsTabPanel
                    key={x.helideckDetails.id}
                    activeTab={activeTab}
                    index={index}
                >
                    <HelideckReport siteReport={siteReport} helideck={x} />
                </DetailsTabPanel>
            ))}
        </div>
    );
}
