import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TemperatureIcon from "../../icons-v2/TempLight.png";
import LabelValue from "../core/LabelValue";
import { CardHeader, Grid } from "@material-ui/core";
import { TemperatureAndHumidityDto } from "../../api/ifis";

export default function TemperatureAndHumidityCard({
    temperatureAndHumidity,
}: {
    temperatureAndHumidity: TemperatureAndHumidityDto | null | undefined;
}) {
    if (temperatureAndHumidity == null) {
        return null;
    }

    return (
        <Card variant="outlined">
            <CardHeader
                title="Temperature and humidity"
                avatar={<img src={TemperatureIcon} alt="temperature" />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Air Temperature"
                            value={temperatureAndHumidity.airTemperature}
                            slashesWhenEmpty
                            unitKey={"temp"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Dew Point"
                            value={temperatureAndHumidity.dewPoint}
                            slashesWhenEmpty
                            unitKey={"temp"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Relative Air Humidity"
                            value={temperatureAndHumidity.relativeAirHumidity}
                            slashesWhenEmpty
                            unitKey={"humidity"}
                        ></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
