import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ConditionsIcon from "../../icons-v2/WeatherConditionsLight.png";
import { CardHeader, Grid } from "@material-ui/core";
import LabelValue from "../core/LabelValue";
import { WeatherReport } from "../../api/ifis";

export default function WeatherCard({
    weather,
}: {
    weather: WeatherReport | null | undefined;
}) {
    return (
        <Card variant="outlined">
            <CardHeader
                title="Weather conditions"
                avatar={<img src={ConditionsIcon} alt="conditions" />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Present weather"
                            value={weather?.metarPresentWeather}
                            slashesWhenEmpty
                        ></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
