// Material-UI not-yet-released fix for findDOMNode depcrecation. See https://stackoverflow.com/a/64135466
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import lightGreen from "@material-ui/core/colors/lightGreen";

export default createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: lightGreen["A400"],
        },
        secondary: {
            main: lightGreen["A400"],
        },
    },
});
