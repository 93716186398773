import { useState, useEffect, useCallback } from "react";
import { tagWithDataSourceType } from "../api/api";
import { LightningDto } from "../api/ifis";
import { useApi } from "../contexts/ApiContext";
import { DataSourceType } from "./useDataSources";

export default function useLightning(enabled: boolean) {
    const [lightning, setLightning] = useState<LightningDto[]>([]);
    const { getJson } = useApi();

    const updateLightning = useCallback(async () => {
        const response = await getJson(`/meteorology/lightning`);
        if (!response) return;
        setLightning(tagWithDataSourceType(response, DataSourceType.Lightning));
    }, [getJson, setLightning]);

    useEffect(() => {
        if (!enabled) {
            setLightning([]);
            return;
        }

        updateLightning();

        const pollInterval = 5000; //5sec
        const timerId = setInterval(() => {
            updateLightning();
        }, pollInterval);

        return () => clearInterval(timerId);
    }, [enabled, updateLightning]);

    return lightning;
}
