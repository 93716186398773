import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CloudsIcon from "../../icons-v2/CloudsLight.png";
import { CardHeader, Grid } from "@material-ui/core";
import LabelValue from "../core/LabelValue";
import { CloudReport } from "../../api/ifis";

export default function CloudCard({
    cloud,
}: {
    cloud: CloudReport | null | undefined;
}) {
    if (cloud == null) {
        return null;
    }

    const baseLayer =
        cloud.cloudLayers.length > 0 ? cloud.cloudLayers[0] : null;

    return (
        <Card variant="outlined">
            <CardHeader
                title="Clouds"
                avatar={<img src={CloudsIcon} alt="clouds" />}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Cloud cover"
                            value={baseLayer?.cover}
                            slashesWhenEmpty
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Cloud height"
                            value={
                                baseLayer != null
                                    ? baseLayer.height * 100
                                    : null
                            }
                            slashesWhenEmpty
                            unitKey={"cloudHeight"}
                        ></LabelValue>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LabelValue
                            label="Vertical visibility"
                            value={cloud.cloudStatus?.verticalVisibility}
                            slashesWhenEmpty
                            unitKey={"visibility"}
                        ></LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
