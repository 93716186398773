import DayIcon from "../../icons-v2/DayDark.png";
import NightIcon from "../../icons-v2/NightDark.png";
import IconSwitch from "./IconSwitch";
import { useSettings } from "../../contexts/SettingsContext";

export default function AircraftClassSwitch({ ...others }: any) {
    const { operationService } = useSettings();
    return (
        <IconSwitch
            leftIcon={DayIcon}
            rightIcon={NightIcon}
            isRight={operationService.isNight}
            toggle={operationService.toggleOperationTime}
            {...others}
        />
    );
}
