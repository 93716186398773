import tinygradient from "tinygradient";

export type AltitudeColorStop = {
    altitude: number;
    color: string;
};

export const groundColor = "#00ff00";

export const altitudeColorStops: AltitudeColorStop[] = [
    { altitude: 0, color: "#00ffff" },
    { altitude: 1000, color: "#00aaff" },
    { altitude: 6000, color: "#0000ff" },
    { altitude: 10000, color: "#aa00ff" },
    { altitude: 35000, color: "#ff00ff" },
    { altitude: 65000, color: "#ff0000" },
];

export const getAltitudeColor = (altitude: number) => {
    if (altitude <= 0) return altitudeColorStops[0].color;
    for (let i = 1; i < altitudeColorStops.length; i++) {
        if (altitude <= altitudeColorStops[i].altitude) {
            const start = altitudeColorStops[i - 1];
            const stop = altitudeColorStops[i];
            const position =
                (altitude - start.altitude) / (stop.altitude - start.altitude);
            const gradient = tinygradient([start.color, stop.color]);
            return gradient.rgbAt(position).toHexString();
        }
    }
    return altitudeColorStops[altitudeColorStops.length - 1].color;
};
