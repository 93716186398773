import { LineLayout, LinePaint, Map, Popup } from "mapbox-gl";

type GeoJsonSymbolSourceOptions = {
    name: string;
    icon?: string;
    sdf?: boolean;
    layout?: LineLayout;
    paint?: LinePaint;
    popUp?: boolean;
    popUpDisplayHtml?: (properties: any) => string;
};

export function addSourceWithLineLayer(
    map: Map,
    { name, layout, paint, popUp, popUpDisplayHtml }: GeoJsonSymbolSourceOptions
) {
    if (map == null || map.getSource(name)) {
        return;
    }

    const layerName = name + "-layer";

    map.addSource(name, {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: [],
        },
    });

    map.addLayer({
        id: layerName,
        type: "line",
        source: name,
        layout: layout || {},
        paint: paint || {},
    });

    map.on("click", layerName, function (e: any) {
        if (e.features == null) {
            return;
        }

        if (popUp && popUpDisplayHtml != null) {
            new Popup()
                .setLngLat(e.lngLat)
                .setHTML(popUpDisplayHtml(e.features[0].properties))
                .addTo(map);
        }
    });

    map.on("mouseenter", layerName, function () {
        map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", layerName, function () {
        map.getCanvas().style.cursor = "";
    });
}
