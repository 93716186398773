import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from "@material-ui/core";
import {
    HelideckReport as IfisHelideckReport,
    SiteReportDto,
} from "../../../api/ifis";
import { useSettings } from "../../../contexts/SettingsContext";
import { DataSourceType } from "../../../hooks/useDataSources";
import HelideckStatusLight from "../HelideckStatusLight";
import Helideck from "./Helideck";
import HelideckConfig from "./HelideckConfig";
import Weather from "./Weather";
import Wind from "./Wind";
import { ReactComponent as FavoriteIcon } from "../../../icons-v2/Favorite.svg";

export default function HelideckReport({
    siteReport,
    helideck,
}: {
    siteReport: SiteReportDto;
    helideck: IfisHelideckReport;
}) {
    const { favoritesService } = useSettings();
    const theme = useTheme();

    const toggleFavorite = (helideckReport: any) => {
        if (favoritesService.hasFavorite(helideckReport)) {
            favoritesService.removeFromFavorites(helideckReport);
            return;
        }

        favoritesService.addToFavorites({
            siteReport: {
                dataSourceType: DataSourceType.Weather,
                name: siteReport.name,
            },
            helideckReport,
        });
    };

    return (
        <div>
            <Card variant="outlined">
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid
                            item
                            container
                            xs={8}
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Typography variant="h5">
                                {helideck.helideckDetails.id}
                            </Typography>
                            <IconButton
                                data-testid={`helideckFavoriteButton-${helideck.helideckDetails.id}`}
                                onClick={() => toggleFavorite(helideck)}
                            >
                                <FavoriteIcon
                                    fill={
                                        favoritesService.hasFavorite(helideck)
                                            ? theme.palette.secondary.light
                                            : theme.palette.text.secondary
                                    }
                                    stroke={
                                        favoritesService.hasFavorite(helideck)
                                            ? theme.palette.secondary.light
                                            : theme.palette.text.secondary
                                    }
                                />
                            </IconButton>
                        </Grid>
                        <Grid item container xs={4} justify="flex-end">
                            <HelideckStatusLight report={helideck} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Wind
                wind={siteReport.wind?.averageWind10Min}
                gust={siteReport.wind?.gust}
            ></Wind>
            <Weather siteReport={siteReport} helideck={helideck}></Weather>
            <Helideck helideck={helideck}></Helideck>
            <HelideckConfig />
        </div>
    );
}
