import { useEffect, useState } from "react";
import { UserImage } from "../api/ifis";
import { useApi } from "../contexts/ApiContext";

type UserImageUpload = {
    imageBase64: string;
    mimeType: string;
    landingSiteId: string;
};

export default function useUserImages(landingSiteId: string, refresh: boolean) {
    const [images, setImages] = useState<UserImage[]>([]);
    const { getJson, postJson } = useApi();

    useEffect(() => {
        if (!refresh) return;
        getJson(
            `/userimage/landingsite/${landingSiteId}`
        ).then((response: any) => setImages(response));
    }, [getJson, setImages, landingSiteId, refresh]);

    const uploadImage = (upload: UserImageUpload) => {
        return postJson(`/userimage`, JSON.stringify(upload));
    };

    return { images, uploadImage };
}
