import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import LabelValue from "../../core/LabelValue";
import { WindObservation } from "../../../api/ifis";

export default function Wind({
    wind,
    gust,
}: {
    wind: WindObservation | null | undefined;
    gust: WindObservation | null | undefined;
}) {
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container>
                    <Grid item xs={6}>
                        <LabelValue label="Wind Speed">
                            <LabelValue
                                label="Avg"
                                value={wind?.speed}
                                slashesWhenEmpty
                                unitKey={"windSpeed"}
                            />
                            <LabelValue
                                label="Max"
                                value={gust?.speed}
                                slashesWhenEmpty
                                unitKey={"windSpeed"}
                            />
                            <LabelValue
                                label="Min"
                                value={null /* lull */}
                                slashesWhenEmpty
                                unitKey={"windSpeed"}
                            />
                        </LabelValue>
                    </Grid>
                    <Grid item xs={6}>
                        <LabelValue label="Wind Direction">
                            <LabelValue
                                label="Avg"
                                value={wind?.direction}
                                slashesWhenEmpty
                                unitKey={"windDirection"}
                            />
                            <LabelValue
                                label="Max"
                                value={wind?.directionRange?.maxDirection}
                                slashesWhenEmpty
                                unitKey={"windDirection"}
                            />
                            <LabelValue
                                label="Min"
                                value={wind?.directionRange?.minDirection}
                                slashesWhenEmpty
                                unitKey={"windDirection"}
                            />
                        </LabelValue>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
