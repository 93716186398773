import Fab from "@material-ui/core/Fab";
import ConfigureIcon from "../../icons-v2/Settings.png";
import {
    createStyles,
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        invokeMenuButton: {
            position: "fixed",
            top: "auto",
            left: "auto",
        },
    })
);

const InvokeMenuButton = ({ onInvokeMenuClick }: any) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Fab
            data-sample-id="OpenMenuButton"
            className={classes.invokeMenuButton}
            color={"default"}
            onClick={onInvokeMenuClick}
            size={isLarge ? "large" : "small"}
            style={{
                bottom: isLarge ? 20 : 8,
                right: isLarge ? 20 : 8,
            }}
        >
            <img src={ConfigureIcon} alt="InvokeMenu"></img>
        </Fab>
    );
};

export default InvokeMenuButton;
