import Hidden from "@material-ui/core/Hidden/Hidden";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import logo from "./MMAviation_symbol_ORIG.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoContainer: {
            zIndex: 10,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            padding: 8,
            textDecoration: "none",
            width: 110,
            [theme.breakpoints.only("xs")]: { width: 40 },
        },
        logo: {
            width: "70%",
            height: "70%",
        },
        logoCircle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 25,
            height: 25,
            background: "#f5f5f5",
            borderRadius: "50%",
            border: "1px solid #383838",
        },
        text: {
            color: "white",
            fontSize: 12,
            fontWeight: 600,
            paddingLeft: 4,
            textShadow:
                "-1px 0 #383838, 0 1px #383838, 1px 0 #383838, 0 -1px #383838",
        },
    })
);

export default function Logo() {
    const classes = useStyles();

    return (
        <a
            className={classes.logoContainer}
            href="https://mmaviation.com"
            target="_blank"
            rel="noreferrer noopener nofollow"
        >
            <div className={classes.logoCircle}>
                <img className={classes.logo} src={logo} alt="logo"></img>
            </div>
            <Hidden only="xs">
                <span className={classes.text}>MM Aviation</span>
            </Hidden>
        </a>
    );
}
