import { GeoJSONSource, Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { addRectIcon, addSymbolLayer } from "../addSymbolLayer";
import AnchorIcon from "../../../../icons-v2/AnchorBlack.png";
import BoatIcon from "../../../../icons-v2/VesselSmall.png";
import VesselMediumIcon from "../../../../icons-v2/VesselMedium.png";
import VesselLargeIcon from "../../../../icons-v2/VesselLarge.png";
import { emptyFeatureCollection } from "../../../../utils/geojsonMapping";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { addGeoJsonSource } from "../addGeoJsonSource";
import { MarineVesselDto } from "../../../../api/ifis";

export default function useMarineSource(
    marineVessels: MarineVesselDto[],
    map: Map | null,
    loadedMapStyle: string | null,
    onSourceSelected: (source: MarineVesselDto | undefined) => void
) {
    const marineVesselsRef = useRef<MarineVesselDto[]>();

    useEffect(() => {
        marineVesselsRef.current = marineVessels;
    }, [marineVessels]);

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const sourceName = "marine";
        if (map.getSource(sourceName)) {
            return;
        }

        const onClick = (e: any) => {
            if (e.features == null) {
                return;
            }
            onSourceSelected(
                marineVesselsRef.current?.find(
                    (x) => x.mmsi === e.features[0].properties?.id
                )
            );
        };

        const boatIcon = addRectIcon(
            map,
            sourceName,
            BoatIcon,
            9,
            30,
            false,
            "boat"
        );
        const vesselMediumIcon = addRectIcon(
            map,
            sourceName,
            VesselMediumIcon,
            10,
            40,
            false,
            "medium"
        );
        const vesselLargeIcon = addRectIcon(
            map,
            sourceName,
            VesselLargeIcon,
            12,
            50,
            false,
            "large"
        );

        addGeoJsonSource(map, sourceName, {
            clusterColor: "#B2B2B2",
            clusterIcon: AnchorIcon,
            clusterIconLayout: {
                "icon-rotation-alignment": "map",
            },
        });

        addSymbolLayer(map, {
            sourceName: sourceName,
            layout: {
                "icon-image": [
                    "step",
                    ["get", "length"],
                    boatIcon,
                    100,
                    vesselMediumIcon,
                    200,
                    vesselLargeIcon,
                ],
                "icon-size": 0.8,
                "icon-rotate": ["get", "rotation"],
                "icon-rotation-alignment": "map",
                "icon-allow-overlap": true,
            },
            filter: ["!", ["has", "point_count"]],
            onClick,
        });
    }, [map, loadedMapStyle, marineVessels, onSourceSelected]);

    const getVesselLength = (vessel: any) => {
        return +vessel.a + +vessel.b;
    };

    useEffect(() => {
        if (map == null) {
            return;
        }

        const marineVesselSource = map.getSource("marine") as GeoJSONSource;

        if (marineVesselSource == null) {
            return;
        }

        if (!marineVessels) {
            marineVesselSource.setData(emptyFeatureCollection());
        }

        const marineVesselFeatures: FeatureCollection<
            Geometry,
            GeoJsonProperties
        > = {
            type: "FeatureCollection",
            features: marineVessels.map((a: MarineVesselDto) => {
                return {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [a.lon, a.lat],
                    },
                    properties: {
                        id: a.mmsi,
                        rotation: +(a.heading ?? 0) - 90,
                        length: getVesselLength(a),
                    },
                };
            }),
        };

        marineVesselSource.setData(marineVesselFeatures);
    }, [map, marineVessels, loadedMapStyle]);
}
