import { GeoJSONSource, Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { addSymbolLayer } from "../addSymbolLayer";
import CameraIcon from "../../../../icons-v2/Camera.png";
import { addGeoJsonSource } from "../addGeoJsonSource";
import { WebcamDto } from "../../../../api/ifis";
import { DataSourceType } from "../../../../hooks/useDataSources";
import { mapToFeatureCollection } from "../../../../utils/geojsonMapping";

export default function useRoadCameraSource(
    webcams: WebcamDto[],
    map: Map | null,
    loadedMapStyle: string | null,
    onSourceSelected: (source: WebcamDto | undefined) => void
) {
    const webcamsRef = useRef<WebcamDto[]>();

    useEffect(() => {
        webcamsRef.current = webcams;
    }, [webcams]);

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        const sourceName = "roadcams";
        if (map.getSource(sourceName)) {
            return;
        }

        const onClick = (e: any) => {
            if (e.originalEvent.cancelBubble) {
                return;
            }
            if (e.features == null) {
                return;
            }

            const cam = webcamsRef.current?.find(
                (x) =>
                    x.id === String(e.features[0].id) ||
                    x.id === e.features[0].properties.id
            );
            if (cam === undefined) {
                return;
            }

            onSourceSelected({
                dataSourceType: DataSourceType.RoadCamera,
                id: cam.id,
                description: cam.description,
                imageUrl: cam.imageUrl,
                timestamp: cam.timestamp,
                lon: cam.lon,
                lat: cam.lat,
            });
        };

        addGeoJsonSource(map, sourceName, {
            clusterColor: "#B2B2B2",
            clusterIcon: CameraIcon,
            clusterMaxZoom: 15,
        });

        // add icon layer
        addSymbolLayer(map, {
            sourceName: sourceName,
            icon: CameraIcon,
            size: 24,
            layout: {
                "icon-allow-overlap": true,
                "icon-image": "camera-icon",
                "icon-offset": [
                    "step",
                    ["get", "point_count"],
                    ["literal", [0, 0]],
                    2,
                    ["literal", [0, -20]],
                ],
            },
            filter: ["!", ["has", "point_count"]],
            onClick,
        });
    }, [map, loadedMapStyle, webcamsRef, webcams, onSourceSelected]);

    useEffect(() => {
        if (map == null) return;

        const source = map.getSource("roadcams") as GeoJSONSource;

        if (source == null) return;

        source.setData(
            mapToFeatureCollection(webcams, "id", ["imageUrl", "description"])
        );
    }, [map, webcams, loadedMapStyle]);
}
