import {
    CircularProgress,
    Paper,
    Theme,
    Typography,
    Box,
} from "@material-ui/core";

export default function Splash({
    message,
    theme,
}: {
    message: string;
    theme: Theme;
}) {
    return (
        <Paper
            style={{
                height: "100vh",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                css={{ height: "100%" }}
            >
                <Box>
                    <Typography
                        variant="h6"
                        style={{ color: theme.palette.text.primary }}
                    >
                        {message}
                    </Typography>
                </Box>
                <Box>
                    <CircularProgress color="primary" />
                </Box>
            </Box>
        </Paper>
    );
}
