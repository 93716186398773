import { GeoJSONSource, Map } from "mapbox-gl";
import { useEffect } from "react";
import { useSettings } from "../../../../contexts/SettingsContext";
import { addSourceWithLineLayer } from "../addSourceWithLineLayer";
import { addSymbolLayer } from "../addSymbolLayer";
import { emptyFeatureCollection } from "../../../../utils/geojsonMapping";
import circle from "@turf/circle";

export default function useAreaCircleSource(
    viewLon: number,
    viewLat: number,
    map: Map | null,
    loadedMapStyle: string | null,
    isTracking: boolean
) {
    const { zoom } = useSettings();

    useEffect(() => {
        if (map == null || !loadedMapStyle) {
            return;
        }

        if (map.getSource("area-circle")) {
            return;
        }

        addSourceWithLineLayer(map, {
            name: "area-circle",
            paint: {
                "line-color": "black",
                "line-width": 2,
                "line-opacity": 0.8,
            },
        });

        addSymbolLayer(map, {
            sourceName: "area-circle",
            layout: {
                "text-field": "10km",
                "symbol-placement": "line",
                "text-size": 12,
                "text-offset": [0, -1],
            },
            paint: {
                "text-color": "#505050",
            },
        });

        addSourceWithLineLayer(map, {
            name: "area-circle-5",
            paint: {
                "line-color": "black",
                "line-width": 1.5,
                "line-opacity": 0.8,
            },
        });

        addSymbolLayer(map, {
            sourceName: "area-circle-5",
            layout: {
                "text-field": "5km",
                "symbol-placement": "line",
                "text-size": 12,
                "text-offset": [0, -1],
            },
            paint: {
                "text-color": "#505050",
            },
        });

        addSourceWithLineLayer(map, {
            name: "area-circle-1",
            paint: {
                "line-color": "black",
                "line-width": 1.5,
                "line-opacity": 0.8,
            },
        });

        addSymbolLayer(map, {
            sourceName: "area-circle-1",
            layout: {
                "text-field": "1km",
                "symbol-placement": "line",
                "text-size": 12,
                "text-offset": [0, -1],
            },
            paint: {
                "text-color": "#505050",
            },
        });

        addSourceWithLineLayer(map, {
            name: "area-crosshair",
        });
    }, [map, loadedMapStyle]);

    useEffect(() => {
        if (!loadedMapStyle || map == null) {
            return;
        }

        const source = map.getSource("area-circle") as GeoJSONSource;
        const source5 = map.getSource("area-circle-5") as GeoJSONSource;
        const source1 = map.getSource("area-circle-1") as GeoJSONSource;

        if (source == null) {
            return;
        }

        const options = {
            steps: 50,
            units: "kilometers",
        };

        const circle10 = circle([viewLon, viewLat], 10, {
            ...options,
            properties: { color: "black", width: 2 },
        } as any);
        const circle5 = circle([viewLon, viewLat], 5, {
            ...options,
            properties: { color: "black", width: 2 },
        } as any);
        const circle1 = circle([viewLon, viewLat], 1, {
            ...options,
            properties: { color: "black", width: 2 },
        } as any);

        source.setData(circle10);
        source5.setData(
            zoom.zoomLevel > 10 ? circle5 : emptyFeatureCollection()
        );
        source1.setData(
            zoom.zoomLevel > 11 ? circle1 : emptyFeatureCollection()
        );
    }, [zoom.zoomLevel, loadedMapStyle, map, viewLon, viewLat, isTracking]);
}
