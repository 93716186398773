import { GeoJSONSource, Map } from "mapbox-gl";
import { useEffect, useState } from "react";
import { addSymbolLayer } from "../addSymbolLayer";
import { emptyFeatureCollection } from "../../../../utils/geojsonMapping";
import { addGeoJsonSource } from "../addGeoJsonSource";
import LocationIcon from "../../../../icons/user-location.svg";

export default function useUserLocationSource(
    map: Map | null,
    loadedMapStyle: string | null,
    isTracking: boolean,
    trackedLocation: GeolocationCoordinates | null,
    demoMode: boolean
) {
    const [orientation, setOrientation] = useState<number>(0);
    useEffect(() => {
        if (map == null || !loadedMapStyle || demoMode) {
            return;
        }

        const sourceName = "userLocation";
        if (map.getSource(sourceName)) {
            return;
        }

        addGeoJsonSource(map, sourceName, { clusterColor: "#B2B2B2" });

        // add icon
        addSymbolLayer(map, {
            sourceName: sourceName,
            icon: LocationIcon,
            size: 40,
            layout: {
                "icon-allow-overlap": true,
                "icon-rotate": ["get", "rotation"],
                "icon-rotation-alignment": "map",
            },
        });
    }, [demoMode, loadedMapStyle, map]);

    useEffect(() => {
        if (demoMode) {
            return;
        }

        const handleOrientation = (event: DeviceOrientationEvent) => {
            setOrientation(event.alpha ?? 0);
        };
        if (window.DeviceOrientationEvent) {
            window.addEventListener(
                "deviceorientation",
                handleOrientation,
                true
            );
        }

        return () => {
            window.removeEventListener(
                "deviceorientation",
                handleOrientation,
                true
            );
        };
    }, [demoMode]);

    useEffect(() => {
        if (map == null || demoMode) return;
        const source = map.getSource("userLocation") as GeoJSONSource;
        if (source == null) return;
        if (trackedLocation == null) {
            source.setData(emptyFeatureCollection());
            return;
        }
        source.setData({
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [
                            trackedLocation.longitude,
                            trackedLocation.latitude,
                        ],
                    },
                    properties: {
                        id: 0,
                        rotation: orientation,
                    },
                },
            ],
        });

        map.setLayoutProperty(
            "userLocation-symbol-layer",
            "icon-size",
            isTracking ? 0.5 : 1
        );
    }, [
        loadedMapStyle,
        isTracking,
        map,
        trackedLocation,
        trackedLocation?.latitude,
        trackedLocation?.longitude,
        orientation,
        demoMode,
    ]);
}
