import { HelideckReport } from "../../api/ifis";
import { useSettings } from "../../contexts/SettingsContext";

const statusColors = ["gray", "green", "orange", "red"];

export default function HelideckStatusLight({
    report,
}: {
    report: HelideckReport;
}) {
    const { operationService } = useSettings();
    const statusIndex = operationService.isNight()
        ? operationService.isHeavy()
            ? report.statusHeavyNight
            : report.statusMediumNight
        : operationService.isHeavy()
        ? report.statusHeavyDay
        : report.statusMediumDay;
    const statusColor = statusColors[statusIndex];

    return (
        <svg width={40} height={40} viewBox="0 0 100 100">
            <circle style={{ fill: statusColor }} cx="50" cy="50" r="50" />
        </svg>
    );
}
